var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var yL,DL,yga,zga,Aga,Bga,Cga,Dga;yL=function(a){return $CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N};$CLJS.AL=function(a){var b=function(){var c=yL(a);return c?(c=$CLJS.oe($CLJS.Zd(a)))?(c=$CLJS.lk.g($CLJS.Zd(a)),$CLJS.m(c)?c:$CLJS.CG.g($CLJS.Zd(a))):c:c}();return $CLJS.m(b)?b:$CLJS.zL.g(a)};
DL=function(a,b){return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ml,"valid MBQL clause",$CLJS.Yu,function(c){c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.sl);return["invalid MBQL clause: ",$CLJS.aj.l($CLJS.G([c]))].join("")}],null),$CLJS.$f(yL)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.BL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ml,b],null),function(c){c=$CLJS.AL(c);
return CL(c,a)}],null)],null)};yga=new $CLJS.N("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);zga=new $CLJS.N("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Aga=new $CLJS.N("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.EL=new $CLJS.N(null,"expr","expr",745722291);
$CLJS.FL=new $CLJS.N("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.GL=new $CLJS.N("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.HL=new $CLJS.N("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);$CLJS.IL=new $CLJS.N("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);
$CLJS.JL=new $CLJS.N("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.KL=new $CLJS.N("lib","expression-name","lib/expression-name",-1799326590);Bga=new $CLJS.N("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.LL=new $CLJS.N("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);
$CLJS.ML=new $CLJS.N("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.NL=new $CLJS.N("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Cga=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Dga=new $CLJS.N("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);
$CLJS.BL=new $CLJS.N("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);$CLJS.OL=new $CLJS.N("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.PL=new $CLJS.N("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.QL=new $CLJS.N("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.zL=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.schema.expression","type-of-method"),function(f){var g=$CLJS.BH(f);return $CLJS.F.h(g,$CLJS.BG)?$CLJS.Ob(f):g},$CLJS.Pj,e,a,b,c,d)}();
$CLJS.iL(zga,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,$CLJS.JL],null),$CLJS.wL],null));$CLJS.zL.o(null,$CLJS.Pj,function(a){throw $CLJS.zj($CLJS.TH("{0}: Don''t know how to determine the type of {1}",$CLJS.G([Cga,$CLJS.aj.l($CLJS.G([a]))])),new $CLJS.k(null,1,[$CLJS.EL,a],null));});$CLJS.zL.o(null,$CLJS.ML,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.AL(a)});
var CL=function CL(a,b){return $CLJS.le(a)?$CLJS.Xf(function(d){return CL.h?CL.h(d,b):CL.call(null,d,b)},a):$CLJS.le(b)?$CLJS.Xf(function(d){return CL.h?CL.h(a,d):CL.call(null,a,d)},b):$CLJS.F.h(a,$CLJS.JL)?!0:$CLJS.NH(a,b)};$CLJS.iL($CLJS.HL,DL($CLJS.Dl,"expression returning a boolean"));$CLJS.iL($CLJS.OL,DL($CLJS.Vl,"expression returning a string"));$CLJS.iL($CLJS.QL,DL($CLJS.jl,"expression returning an integer"));$CLJS.iL(Aga,DL($CLJS.JK,"expression returning a non-integer real number"));
$CLJS.iL($CLJS.PL,DL($CLJS.Hl,"expression returning a number"));$CLJS.iL(Bga,DL($CLJS.NI,"expression returning a date"));$CLJS.iL(Dga,DL($CLJS.WK,"expression returning a time"));$CLJS.iL(yga,DL($CLJS.RJ,"expression returning a date time"));$CLJS.iL($CLJS.GL,DL($CLJS.Ol,"expression returning a date, time, or date time"));$CLJS.RL=new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.Hl,null,$CLJS.Vl,null,$CLJS.Ol,null,$CLJS.Dl,null],null),null);$CLJS.iL($CLJS.NL,DL($CLJS.RL,"an expression that can be compared with :\x3e or :\x3c"));
$CLJS.iL($CLJS.LL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,DL(new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.Hl,null,$CLJS.Vl,null,$CLJS.Ol,null,$CLJS.Dl,null],null),null),"an expression that can appear in :\x3d or :!\x3d")],null));$CLJS.iL($CLJS.IL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,DL($CLJS.pl,"any type of expression")],null));
$CLJS.iL($CLJS.FL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ol,new $CLJS.k(null,1,[$CLJS.rk,1],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kq,$CLJS.IL],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.wl,$CLJS.Wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.KL,$CLJS.Rl],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pm,$CLJS.Wt],null)],null)],null)],null));