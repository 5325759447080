import styled from "@emotion/styled";

export const BrowseHeaderRoot = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const BrowseHeaderContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;
