var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var NN,yha,QN,RN,SN,TN,VN,WN,XN,YN,aO,bO,cO,dO,eO,fO,gO,iO,jO,zha,Aha,Bha,Cha,Dha,Eha,lO,Fha,Gha,Hha,Iha,nO,oO,pO,qO,Jha,rO,sO,Kha,Lha,Mha,uO,wO,Nha,xO,yO,Oha,Pha,zO,AO,Qha,BO,CO,EO,FO,Rha,GO,Sha,IO,Tha,Uha,JO,LO,Vha,Wha,NO,OO,Xha,Yha,RO,Zha,SO,$ha,VO,WO,XO,aia,bia,cia,YO,ZO,$O,dia,eia,aP,fia,gia,hia,bP,dP,eP,iia,jia,kia,lia,mia,fP,gP,hP,nia,iP,oia,pia,qia,jP,ria,sia,tia,uia,kP,via,lP,wia,nP,oP,pP,qP,rP,xia,vP,yia,zia,wP,xP,Aia,yP,zP,Bia,BP,CP,DP,FP,GP,Cia,HP,Dia,Eia,Fia,IP,JP,KP,NP,
Gia,PP,QP,RP,SP,Hia,Iia,Jia,UP,VP,Kia,WP,Lia,Mia,Nia,Oia,$P,Pia,Qia,aQ,bQ,cQ,Ria,dQ,eQ,fQ,Sia,gQ,Tia,Uia,hQ,iQ,jQ,lQ,mQ,Via,oQ,pQ,qQ,rQ,Wia,tQ,uQ,Xia,wQ,xQ,yQ,zQ,AQ,BQ,Yia,Zia,$ia,aja,bja,DQ,cja,dja,EQ,FQ,GQ,eja,HQ,fja,IQ,gja,hja,JQ,ija,jja,kja,lja,LQ,MQ,mja,nja,oja,PQ,pja,QQ,qja,RQ,rja,TQ,UQ,sja,VQ,WQ,XQ,YQ,ZQ,tja,$Q,uja,aR,vja,wja,bR,cR,dR,xja,yja,eR,zja,Aja,fR,Bja,Cja,Dja,Eja,iR,Fja,Gja,Hja,Ija,Jja,Kja,Lja,mR,nR,oR,pR,qR,Mja,rR,sR,tR,uR,Nja,Oja,Pja,Qja,vR,wR,xR,Rja,yR,zR,Sja,Tja,AR,Uja,Vja,BR,
Wja,DR,ER,Xja,FR,Yja,HR,IR,Zja,JR,KR,$ja,LR,aka,MR,bka,OR,PR,QR,RR,cka,SR,dka,TR,eka,UR,VR,YR,fka,gka,hka,$R,aS,ika,bS,jka,kka,lka,cS,mka,dS,nka,fS,gS,oka,pka,kS,lS,qka,mS,nS,oS,pS,rka,ska;NN=function(a,b){if(null!=a&&null!=a.Td)a=a.Td(a,b);else{var c=NN[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=NN._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("CoreSpec.checker",a);}return a};
yha=function(a,b){var c=new $CLJS.k(null,3,[$CLJS.kM,a,$CLJS.nM,!1,$CLJS.lM,$CLJS.cj.g($CLJS.P)],null);return a.h?a.h(b,c):a.call(null,b,c)};$CLJS.ON=function(a,b){return $CLJS.Sb(function(c,d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Ie(a,e)?$CLJS.U.j(c,d,$CLJS.M.h(a,e)):c},$CLJS.R.j($CLJS.Gm,a,$CLJS.gi(b)),b)};$CLJS.PN=function(a,b){return new $CLJS.fN(a,!1,b,null,null,null)};QN=function(a){return new $CLJS.XM(a,null,null,null)};
RN=function(a,b,c,d){this.Nc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};SN=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return SN.l(0<b.length?new $CLJS.z(b.slice(0),0,null):null)};TN=function(a,b,c,d,e){this.kc=a;this.pc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};VN=function(a,b,c){return UN.l($CLJS.G([a,b,$CLJS.ag(!0),c]))};WN=function(a,b,c,d){this.zc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};
XN=function(a){if(!(null!=a?a.C&32768||$CLJS.t===a.Zf||(a.C?0:$CLJS.Nb($CLJS.Jz,a)):$CLJS.Nb($CLJS.Jz,a)))throw Error($CLJS.TL("Not an IDeref: %s",$CLJS.G([a])));return new WN(a,null,null,null)};YN=function(a,b,c,d){this.X=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.ZN=function(a){return new YN(a,null,null,null)};$CLJS.$N=function(a){return $CLJS.Om.h($CLJS.gM,yha(function(b,c){return NN($CLJS.LM(b),c)},a))};
aO=function(a,b){if($CLJS.qe(b)){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null);switch(c instanceof $CLJS.N?c.S:null){case "optional":return new $CLJS.fN($CLJS.ZN(d),!0,a,null,null,null);case "rest":return $CLJS.TM(d,a);default:return $CLJS.PN(b,a)}}else return $CLJS.PN(b,a)};
bO=function(a,b){return $CLJS.Mg($CLJS.nf($CLJS.PN($CLJS.PM(a),a),function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=aO(v,u);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(aO(n,g),
e($CLJS.zd(f)))}return null}},null,null)}($CLJS.wu(2,2,b))}()))};cO=function(a,b){var c=$CLJS.ne(b);return c?(c=$CLJS.C(b)instanceof $CLJS.N)?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c:c};
dO=function(a){return $CLJS.TM($CLJS.R.h(UN,$CLJS.Ve($CLJS.qg,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(cO,u),q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,
1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(cO,l),f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.dv.h(", ",$CLJS.rg.h($CLJS.C,a))].join(""))};eO=function(a){return $CLJS.VM(a,$CLJS.A,"Non-empty")};fO=function(a){return $CLJS.A(a)?$CLJS.R.h($CLJS.Ru,a):!0};gO=function(a){return"string"===typeof a?$CLJS.ki.h(Date.parse(a),NaN):null};
$CLJS.pM.prototype.Td=$CLJS.Ma(56,function(){var a=this;return function(b){var c=a.na.g?a.na.g(b):a.na.call(null,b);return $CLJS.m(c)?c:b}});
$CLJS.uM.prototype.Td=$CLJS.Ma(55,function(a,b){var c=this,d=this,e=$CLJS.Sb(function(f,g){return $CLJS.Iga(g,b,f)},function(f){return $CLJS.eM($CLJS.$L(d,f,new $CLJS.Yi(function(){var g=$CLJS.VL(f);return c.bc.g?c.bc.g(g):c.bc.call(null,g)}),null))},$CLJS.lf(c.options));return $CLJS.m(c.Ub)?function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.fM(g))return g;f=$CLJS.m($CLJS.nM.g(b))?g:f;f=c.Ub.g?c.Ub.g(f):c.Ub.call(null,f);return $CLJS.m(f)?
f:g}:function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);return $CLJS.m(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.CM.prototype.Td=$CLJS.Ma(54,function(a,b){var c=this,d=$CLJS.m($CLJS.nM.g(b))?c.sc:function(){return null},e=$CLJS.AM(c.elements,b,function(f,g){return g});return function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;var l=$CLJS.cj.g($CLJS.Lg);g=e.h?e.h(l,f):e.call(null,l,f);l=$CLJS.r(l);var n=(n=$CLJS.A(g))?n:$CLJS.Xf($CLJS.fM,l);return $CLJS.m(n)?$CLJS.eM(c.dc.j?c.dc.j(f,l,g):c.dc.call(null,f,l,g)):d.g?d.g(l):d.call(null,l)}});$CLJS.hO={};iO={};jO={};$CLJS.h=RN.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "vs":return this.Nc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oN,this.Nc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.oN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2002701468^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Nc,b.Nc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.oN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new RN(this.Nc,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "vs":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.oN,b):$CLJS.O.call(null,$CLJS.oN,b))?new RN(c,this.F,this.m,null):new RN(this.Nc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.oN,this.Nc)],null),this.m))};$CLJS.h.O=function(a,b){return new RN(this.Nc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.qM($CLJS.iM(this,function(b){return $CLJS.Ie(a.Nc,b)},function(b){return new $CLJS.gf(null,a.Nc,new $CLJS.gf(null,b,null,1,null),2,null)}))};$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.rha,this.Nc)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};SN.l=function(a){return new RN($CLJS.si(a),null,null,null)};SN.v=0;
SN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=TN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":return this.kc;case "error-symbol":return this.pc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qN,this.kc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pN,this.pc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qN,$CLJS.pN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1418435858^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.kc,b.kc)&&$CLJS.F.h(this.pc,b.pc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.qN,null,$CLJS.pN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new TN(this.kc,this.pc,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.qN,b):$CLJS.O.call(null,$CLJS.qN,b))?new TN(c,this.pc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.pN,b):$CLJS.O.call(null,$CLJS.pN,b))?new TN(this.kc,c,this.F,this.m,null):new TN(this.kc,this.pc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.qN,this.kc),new $CLJS.Je($CLJS.pN,this.pc)],null),this.m))};
$CLJS.h.O=function(a,b){return new TN(this.kc,this.pc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.vM($CLJS.KM,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.k(null,2,[$CLJS.rM,u,$CLJS.fm,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,
null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.rM,l,$CLJS.fm,f],null),d($CLJS.zd(e)))}return null}},null,null)}(a.kc)}(),function(b){var c=a.pc;c=$CLJS.m(c)?c:$CLJS.F.h(1,$CLJS.E(a.kc))?$CLJS.Ui.g($CLJS.WL($CLJS.C($CLJS.C(a.kc)))):$CLJS.Uga;return new $CLJS.gf(null,c,new $CLJS.gf(null,b,null,1,null),2,null)})};
$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.Vga,$CLJS.qg.h($CLJS.sg(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.WL(b)),$CLJS.MM(a)],null)},$CLJS.G([this.kc])),$CLJS.m(this.pc)?new $CLJS.S(null,1,5,$CLJS.T,[this.pc],null):null))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
var UN=function UN(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return UN.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
UN.l=function(a){if(!$CLJS.A(a)||!($CLJS.Zf($CLJS.E(a))||$CLJS.$d(a)instanceof $CLJS.w))throw Error($CLJS.TL("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.G([$CLJS.E(a)])));return new TN($CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null),v=$CLJS.J(q,1,null);q=l;if(!$CLJS.ze(u))throw Error($CLJS.UL(["Conditional predicate ",
$CLJS.p.g(u)," must be a function"].join("")));u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(u,$CLJS.Uu)?$CLJS.ag(!0):u,v],null);q.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);if(!$CLJS.ze(l))throw Error($CLJS.UL(["Conditional predicate ",$CLJS.p.g(l)," must be a function"].join("")));l=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(l,$CLJS.Uu)?$CLJS.ag(!0):l,f],null);return $CLJS.nf(l,d($CLJS.zd(e)))}return null}},
null,null)}($CLJS.wu(2,2,a))}()),$CLJS.Zf($CLJS.E(a))?null:$CLJS.$d(a),null,null,null)};UN.v=0;UN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=WN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return this.zc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sN,this.zc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.sN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1521498755^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.zc,b.zc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.sN,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new WN(this.zc,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.sN,b):$CLJS.O.call(null,$CLJS.sN,b))?new WN(c,this.F,this.m,null):new WN(this.zc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.sN,this.zc)],null),this.m))};$CLJS.h.O=function(a,b){return new WN(this.zc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.wM($CLJS.KM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.fm,$CLJS.r(this.zc)],null)],null))};
$CLJS.h.Fa=function(){if(this.zc instanceof $CLJS.ud){var a=$CLJS.fe(this.zc);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.Ek);b=$CLJS.M.h(b,$CLJS.V);a=$CLJS.Ui.g([$CLJS.p.g(a),"/",$CLJS.p.g(b)].join(""));a=new $CLJS.gf(null,$CLJS.Ml,new $CLJS.gf(null,a,null,1,null),2,null)}else a=$CLJS.sha;return new $CLJS.gf(null,$CLJS.iha,new $CLJS.gf(null,a,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.h=YN.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,this.X],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.fm],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-805411239^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.fm,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new YN(this.X,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.fm,b):$CLJS.O.call(null,$CLJS.fm,b))?new YN(c,this.F,this.m,null):new YN(this.X,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.fm,this.X)],null),this.m))};$CLJS.h.O=function(a,b){return new YN(this.X,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.wM($CLJS.KM,new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.rM,$CLJS.Cb,$CLJS.fm,$CLJS.PM(null)],null),new $CLJS.k(null,1,[$CLJS.fm,this.X],null)],null))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,$CLJS.jha,new $CLJS.gf(null,$CLJS.MM(this.X),null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
zha=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);Aha=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);$CLJS.kO=new $CLJS.N(null,"right-join","right-join",-56349359);Bha=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);Cha=new $CLJS.N(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
Dha=new $CLJS.N(null,"query-hash","query-hash",-1524484965);Eha=new $CLJS.N(null,"from","from",1815293044);lO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Fha=new $CLJS.N(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);Gha=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Hha=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);
$CLJS.mO=new $CLJS.N(null,"aggregation-options","aggregation-options",-1904917550);Iha=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);nO=new $CLJS.N("location","zip_code","location/zip_code",1641155222);oO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);pO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);qO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);Jha=new $CLJS.N(null,"lon-max","lon-max",1590224717);
rO=new $CLJS.w(null,"stddev","stddev",775056588,null);sO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.tO=new $CLJS.N(null,"query","query",-1288509510);Kha=new $CLJS.N(null,"lat-field","lat-field",-830652957);Lha=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);Mha=new $CLJS.w(null,"FieldOptions","FieldOptions",-1536320891,null);uO=new $CLJS.N("location","country","location/country",1666636202);
$CLJS.vO=new $CLJS.N(null,"fields","fields",-1932066230);wO=new $CLJS.w("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);Nha=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);xO=new $CLJS.N(null,"unary","unary",-989314568);yO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Oha=new $CLJS.N(null,"lon-min","lon-min",-787291357);Pha=new $CLJS.N(null,"match","match",1220059550);
zO=new $CLJS.w(null,"count-where","count-where",2025939247,null);AO=new $CLJS.N(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Qha=new $CLJS.N(null,"embedded-question","embedded-question",-2146473954);BO=new $CLJS.w(null,"sum","sum",1777518341,null);CO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.DO=new $CLJS.N("date","range","date/range",1647265776);EO=new $CLJS.w(null,"between","between",-1523336493,null);
FO=new $CLJS.N(null,"clause-form","clause-form",1820463737);Rha=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);GO=new $CLJS.w(null,"field","field",338095027,null);Sha=new $CLJS.N(null,"segment-id","segment-id",1810133590);$CLJS.HO=new $CLJS.N(null,"filter","filter",-948537934);IO=new $CLJS.w(null,"not-null","not-null",313812992,null);Tha=new $CLJS.N(null,"template-tag","template-tag",310841038);
Uha=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);JO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.KO=new $CLJS.N(null,"context","context",-830191113);LO=new $CLJS.w(null,"get-year","get-year",704520253,null);Vha=new $CLJS.N(null,"format-rows?","format-rows?",992129486);$CLJS.MO=new $CLJS.N(null,"parameters","parameters",-1229919748);Wha=new $CLJS.N(null,"json-download","json-download",-971130133);
NO=new $CLJS.N(null,"string-or-field","string-or-field",-1970678542);OO=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Xha=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Yha=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$CLJS.PO=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.QO=new $CLJS.N("date","month-year","date/month-year",1948031290);
RO=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);Zha=new $CLJS.w("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);SO=new $CLJS.w("s","recursive","s/recursive",-1935549792,null);$CLJS.TO=new $CLJS.N(null,"joins","joins",1033962699);$ha=new $CLJS.N(null,"slug","slug",2029314850);$CLJS.UO=new $CLJS.N(null,"source-field","source-field",933829534);VO=new $CLJS.w(null,"Field","Field",430385967,null);
WO=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);XO=new $CLJS.w("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);aia=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);bia=new $CLJS.N(null,"disable-max-results?","disable-max-results?",857693204);cia=new $CLJS.N(null,"items","items",1031954938);YO=new $CLJS.N(null,"more","more",-2058821800);
ZO=new $CLJS.N(null,"first-clause","first-clause",-20953491);$O=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);dia=new $CLJS.w("metabase.mbql.schema","NumericExpression*","metabase.mbql.schema/NumericExpression*",-254856038,null);eia=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);aP=new $CLJS.w(null,"contains","contains",-1977535957,null);fia=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);
gia=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);hia=new $CLJS.w(null,"BooleanExpression","BooleanExpression",1027887421,null);bP=new $CLJS.N("number","\x3d","number/\x3d",-2094581309);$CLJS.cP=new $CLJS.N(null,"fingerprint","fingerprint",598613022);dP=new $CLJS.w(null,"is-null","is-null",-356519403,null);eP=new $CLJS.N(null,"other-clauses","other-clauses",1570511021);iia=new $CLJS.N(null,"map-tiles","map-tiles",1961865797);
jia=new $CLJS.N(null,"required","required",1807647006);kia=new $CLJS.w(null,"Filter*","Filter*",-1193205185,null);lia=new $CLJS.N(null,"datetime-x","datetime-x",1519265947);mia=new $CLJS.N(null,"datetime-y","datetime-y",-1666955771);fP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);gP=new $CLJS.N(null,"date-arithmetics","date-arithmetics",-1832808309);hP=new $CLJS.N("string","contains","string/contains",1602423827);
nia=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);iP=new $CLJS.N("string","!\x3d","string/!\x3d",-1083772573);oia=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);pia=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);qia=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);
jP=new $CLJS.w(null,"share","share",1051097594,null);ria=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);sia=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);tia=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);uia=new $CLJS.N(null,"xlsx-download","xlsx-download",-1622892009);
kP=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);via=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);lP=new $CLJS.N(null,"collection","collection",-683361892);$CLJS.mP=new $CLJS.N("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);wia=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);nP=new $CLJS.N("number","\x3c\x3d","number/\x3c\x3d",-1499316353);
oP=new $CLJS.w(null,"Aggregation","Aggregation",-955933538,null);pP=new $CLJS.w(null,"metric","metric",2049329604,null);qP=new $CLJS.w(null,"concat","concat",-467652465,null);rP=new $CLJS.N(null,"variable","variable",-281346492);$CLJS.sP=new $CLJS.N("date","quarter-year","date/quarter-year",-1453950150);xia=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.tP=new $CLJS.N("date","relative","date/relative",25987732);$CLJS.uP=new $CLJS.N(null,"alias","alias",-2039751630);
vP=new $CLJS.N("location","city","location/city",-1746973325);yia=new $CLJS.N(null,"public-dashboard","public-dashboard",645968405);zia=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);wP=new $CLJS.N("number","\x3e\x3d","number/\x3e\x3d",-1670691032);xP=new $CLJS.N("number","between","number/between",97700581);Aia=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);yP=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
zP=new $CLJS.w("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);$CLJS.AP=new $CLJS.N(null,"semantic_type","semantic_type",272485089);Bia=new $CLJS.N(null,"metric-id","metric-id",-686486942);BP=new $CLJS.w(null,"*","*",345799209,null);CP=new $CLJS.w(null,"+","+",-740910886,null);DP=new $CLJS.w(null,"-","-",-471816912,null);$CLJS.EP=new $CLJS.N(null,"breakout","breakout",-732419050);FP=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);
GP=new $CLJS.N(null,"allowed-for","allowed-for",122724334);Cia=new $CLJS.N(null,"question","question",-1411720117);HP=new $CLJS.w(null,"asc","asc",1997386096,null);Dia=new $CLJS.w("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);Eia=new $CLJS.N(null,"pulse-id","pulse-id",1331432237);Fia=new $CLJS.w("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);
IP=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);JP=new $CLJS.w(null,"\x3c","\x3c",993667236,null);KP=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);$CLJS.LP=new $CLJS.N(null,"aggregation","aggregation",1597476696);$CLJS.MP=new $CLJS.N(null,"source-metadata","source-metadata",-477816085);NP=new $CLJS.N("string","ends-with","string/ends-with",302681156);Gia=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);
$CLJS.OP=new $CLJS.w("s","Str","s/Str",907974338,null);PP=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);QP=new $CLJS.w(null,"and","and",668631710,null);RP=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);SP=new $CLJS.w(null,"round","round",-645002441,null);Hia=new $CLJS.N(null,"to","to",192099007);$CLJS.TP=new $CLJS.N("date","single","date/single",1554682003);Iia=new $CLJS.w(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);
Jia=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);UP=new $CLJS.w(null,"exp","exp",1378825265,null);VP=new $CLJS.w(null,"Filter","Filter",-424893332,null);Kia=new $CLJS.w("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);WP=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Lia=new $CLJS.N(null,"dashboard-id","dashboard-id",1965414288);
Mia=new $CLJS.w(null,"BooleanExpression*","BooleanExpression*",623370113,null);$CLJS.XP=new $CLJS.N(null,"source-table","source-table",-225307692);Nia=new $CLJS.N(null,"embedded-dashboard","embedded-dashboard",-485078014);$CLJS.YP=new $CLJS.N("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);Oia=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);$CLJS.ZP=new $CLJS.N(null,"metric","metric",408798077);
$P=new $CLJS.w(null,"floor","floor",-772394748,null);Pia=new $CLJS.N(null,"middleware","middleware",1462115504);Qia=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);aQ=new $CLJS.N(null,"requires-features","requires-features",-101116256);bQ=new $CLJS.N(null,"clause-name","clause-name",-996419059);cQ=new $CLJS.w(null,"now","now",-9994004,null);Ria=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);
dQ=new $CLJS.w(null,"avg","avg",1837937727,null);eQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);fQ=new $CLJS.N(null,"max-results","max-results",-32858165);Sia=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);gQ=new $CLJS.w(null,"case","case",-1510733573,null);Tia=new $CLJS.w("js","Date","js/Date",946858373,null);Uia=new $CLJS.w(null,"StringExpression*","StringExpression*",1110382599,null);
hQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);iQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);jQ=new $CLJS.w(null,"DatetimeExpression","DatetimeExpression",1944354145,null);$CLJS.kQ=new $CLJS.N(null,"join-alias","join-alias",1454206794);lQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);mQ=new $CLJS.w(null,"StringExpression","StringExpression",-233088561,null);Via=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
$CLJS.nQ=new $CLJS.w("s","pred","s/pred",-727014287,null);oQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);pQ=new $CLJS.N("number","!\x3d","number/!\x3d",-673025509);qQ=new $CLJS.w(null,"abs","abs",1394505050,null);rQ=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Wia=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$CLJS.sQ=new $CLJS.w("s","cond-pre","s/cond-pre",-923707600,null);
tQ=new $CLJS.N(null,"date","date",-1463434462);uQ=new $CLJS.N(null,"second-clause","second-clause",-461435645);$CLJS.vQ=new $CLJS.N(null,"left-join","left-join",-672831855);Xia=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);wQ=new $CLJS.w(null,"rtrim","rtrim",979195078,null);xQ=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);yQ=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);
zQ=new $CLJS.w("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);AQ=new $CLJS.w(null,"or","or",1876275696,null);BQ=new $CLJS.w("s","Int","s/Int",-2116888657,null);$CLJS.CQ=new $CLJS.N(null,"field","field",-1302436500);Yia=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Zia=new $CLJS.w(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);$ia=new $CLJS.N(null,"constraints","constraints",422775616);
aja=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);bja=new $CLJS.N(null,"csv-download","csv-download",2141432084);DQ=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);cja=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);dja=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);
EQ=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);FQ=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);GQ=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);eja=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);HQ=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);
fja=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);IQ=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);gja=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);hja=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);JQ=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
ija=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);jja=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);kja=new $CLJS.N(null,"display_name","display_name",-1494335013);lja=new $CLJS.N(null,"snippet-id","snippet-id",1987785841);$CLJS.KQ=new $CLJS.N(null,"database","database",1849087575);LQ=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
MQ=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);mja=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.NQ=new $CLJS.N(null,"expressions","expressions",255689909);nja=new $CLJS.N(null,"ad-hoc","ad-hoc",-2033634036);oja=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$CLJS.OQ=new $CLJS.N(null,"temporal-unit","temporal-unit",-1892306358);
PQ=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);pja=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);QQ=new $CLJS.w(null,"get-day","get-day",1768100384,null);qja=new $CLJS.w(null,"Fields","Fields",430617156,null);RQ=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.SQ=new $CLJS.N(null,"native","native",-613060878);rja=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);
TQ=new $CLJS.N(null,"page","page",849072397);UQ=new $CLJS.w(null,"length","length",-2065447907,null);sja=new $CLJS.N(null,"dashboard","dashboard",-631747508);VQ=new $CLJS.w(null,"get-week","get-week",752472178,null);WQ=new $CLJS.w(null,"get-month","get-month",1271156796,null);XQ=new $CLJS.w(null,"dimension","dimension",-2111181571,null);YQ=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
ZQ=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);tja=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);$Q=new $CLJS.w(null,"substring","substring",-1513569493,null);uja=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);aR=new $CLJS.N(null,"internal","internal",-854870097);vja=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);
wja=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);bR=new $CLJS.N(null,"declared","declared",92336021);cR=new $CLJS.N(null,"more-values-or-fields","more-values-or-fields",-886177554);dR=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);xja=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);yja=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
eR=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);zja=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Aja=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);fR=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.gR=new $CLJS.N(null,"template-tags","template-tags",1853115685);Bja=new $CLJS.N(null,"public-question","public-question",629369976);
Cja=new $CLJS.w("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);$CLJS.hR=new $CLJS.N(null,"fk-field-id","fk-field-id",1124127357);Dja=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);Eja=new $CLJS.N(null,"binary","binary",-1802232288);iR=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Fja=new $CLJS.N(null,"userland-query?","userland-query?",-123699383);
$CLJS.jR=new $CLJS.N(null,"source-query","source-query",198004422);$CLJS.kR=new $CLJS.N(null,"settings","settings",1556144875);Gja=new $CLJS.w("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);$CLJS.lR=new $CLJS.N(null,"inner-join","inner-join",659431740);Hja=new $CLJS.w(null,"Field*","Field*",-2104480026,null);Ija=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);Jja=new $CLJS.w("s","eq","s/eq",1021992833,null);
Kja=new $CLJS.N(null,"executed-by","executed-by",-739811161);Lja=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);mR=new $CLJS.N(null,"amount","amount",364489504);nR=new $CLJS.w(null,"percentile","percentile",1039342775,null);oR=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);pR=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);qR=new $CLJS.w(null,"trim","trim",-1880116002,null);
Mja=new $CLJS.w(null,"MBQLQuery","MBQLQuery",-1395590243,null);rR=new $CLJS.w(null,"NumericExpression","NumericExpression",-2082340941,null);sR=new $CLJS.w("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",-859895030,null);tR=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);uR=new $CLJS.N("string","\x3d","string/\x3d",983744235);Nja=new $CLJS.N(null,"skip-results-metadata?","skip-results-metadata?",251010463);
Oja=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Pja=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);Qja=new $CLJS.w(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);vR=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);wR=new $CLJS.N(null,"value-or-field","value-or-field",-1387286309);xR=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);
Rja=new $CLJS.N(null,"lat-min","lat-min",1630784161);yR=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);zR=new $CLJS.w(null,"inside","inside",-681932758,null);Sja=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);Tja=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);AR=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);
Uja=new $CLJS.N(null,"process-viz-settings?","process-viz-settings?",-173592315);Vja=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);BR=new $CLJS.N(null,"variadic","variadic",882626057);$CLJS.CR=new $CLJS.N(null,"category","category",-593092832);Wja=new $CLJS.w(null,"NumericExpression*","NumericExpression*",-1459070895,null);DR=new $CLJS.w(null,"upper","upper",1886775433,null);ER=new $CLJS.N(null,"field-or-expression","field-or-expression",-1409494368);
Xja=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);FR=new $CLJS.N(null,"sugar","sugar",-73788488);Yja=new $CLJS.N(null,"lat-max","lat-max",841568226);$CLJS.GR=new $CLJS.N(null,"full-join","full-join",1305476385);HR=new $CLJS.w(null,"power","power",702679448,null);IR=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);Zja=new $CLJS.w("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);
JR=new $CLJS.w(null,"median","median",-2084869638,null);KR=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);$ja=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);LR=new $CLJS.N(null,"y","y",-1757859776);aka=new $CLJS.w("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",-823028985,null);MR=new $CLJS.w("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);
bka=new $CLJS.w("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);$CLJS.NR=new $CLJS.N(null,"binning","binning",1709835866);OR=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);PR=new $CLJS.N(null,"b","b",1482224470);QR=new $CLJS.N(null,"a","a",-2123407586);RR=new $CLJS.w(null,"replace","replace",853943757,null);cka=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
SR=new $CLJS.w(null,"segment","segment",675610331,null);dka=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);TR=new $CLJS.w(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);eka=new $CLJS.w(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);UR=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);VR=new $CLJS.N("string","starts-with","string/starts-with",1266861170);
$CLJS.WR=new $CLJS.N(null,"order-by","order-by",1527318070);YR=new $CLJS.N(null,"advanced-math-expressions","advanced-math-expressions",1076581757);fka=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);gka=new $CLJS.N("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);hka=new $CLJS.w("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);$CLJS.ZR=new $CLJS.N(null,"condition","condition",1668437652);
$R=new $CLJS.N("string","does-not-contain","string/does-not-contain",-1536178964);aS=new $CLJS.w("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);ika=new $CLJS.N(null,"report-timezone","report-timezone",-2053796389);bS=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);jka=new $CLJS.N(null,"card-name","card-name",-2035606807);
kka=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);lka=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);cS=new $CLJS.w(null,"log","log",45015523,null);mka=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);dS=new $CLJS.w(null,"time","time",-1268547887,null);
nka=new $CLJS.w("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);$CLJS.eS=new $CLJS.N(null,"database_type","database_type",-54700895);fS=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);gS=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.hS=new $CLJS.N(null,"clause","clause",1479668060);$CLJS.iS=new $CLJS.N(null,"expression-name","expression-name",-1798450709);
$CLJS.jS=new $CLJS.N("date","all-options","date/all-options",549325958);oka=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);pka=new $CLJS.w(null,"WidgetType","WidgetType",-1985035186,null);kS=new $CLJS.N("location","state","location/state",-114378652);lS=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);qka=new $CLJS.N(null,"lon-field","lon-field",517872067);
mS=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);nS=new $CLJS.N(null,"numeric","numeric",-1495594714);oS=new $CLJS.w(null,"variable","variable",1359185035,null);pS=new $CLJS.w(null,"lower","lower",-1534114948,null);$CLJS.qS=new $CLJS.N(null,"limit","limit",-1355822363);rka=new $CLJS.w("s","enum","s/enum",-975416934,null);ska=new $CLJS.N(null,"pulse","pulse",-244494476);var rS,sS,tS,tka,uka,vka,wka,wS,xS,xka,yka,zka,yS,Aka,zS,AS,BS,Bka,CS,Cka,DS,ES,Dka,Eka,IS,Fka,LS,NS,OS,PS,QS,RS,US,WS,YS,ZS,Gka,$S,aT,bT,cT,dT,eT,fT,gT,hT,iT,jT,kT,lT,mT,nT,oT,pT,qT,rT,sT,tT,uT,vT,SS,xT,yT,zT,AT,BT,CT,DT,ET,FT,GT,HT,IT,JT,KT,LT,MT,NT,MS,QT,RT,ST,TT,UT,VT,WT,XT,YT,ZT,$T,aU,bU,cU,dU,eU,fU,gU,hU,iU,jU,kU,lU,Hka,mU,nU,XS,oU,pU,Ika,Jka,qU,wT,OT,sU,tU,uU,vU,wU,xU,yU,zU,AU,BU,CU,DU,EU,FU,GU,HU,IU,JU,Kka,LU,MU,OU,Lka,Mka,PU,Nka,Oka,Pka,Qka,Rka,RU,TU,UU,WU,Ska,Tka,Uka,XU,YU,Vka,QU,ZU,$U,
aV,Wka,Xka,Yka,Zka,$ka,ala,bla,cla,dla;rS=new $CLJS.ni(null,new $CLJS.k(null,12,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.Zk,null,$CLJS.Pj,null,$CLJS.mk,null,$CLJS.kl,null,$CLJS.dm,null,$CLJS.xl,null,$CLJS.Jj,null,$CLJS.vl,null,$CLJS.Uj,null,$CLJS.gk,null],null),null);sS=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.vz,null,$CLJS.sz,null,$CLJS.Pj,null,$CLJS.bk,null,$CLJS.tz,null,$CLJS.Sk,null,$CLJS.uz,null],null),null);tS=$CLJS.xz.h(rS,sS);tka=$CLJS.TM($CLJS.R.h(SN,rS),"date-bucketing-unit");
uka=$CLJS.TM($CLJS.R.h(SN,sS),"time-bucketing-unit");$CLJS.uS=$CLJS.TM($CLJS.R.h(SN,tS),"datetime-bucketing-unit");$CLJS.vS=$CLJS.TM($CLJS.R.h(SN,$CLJS.SL.tz.names()),"timezone-id");vka=$CLJS.TM($CLJS.R.h(SN,new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.YK,null,$CLJS.mk,null,$CLJS.bk,null,$CLJS.dm,null,$CLJS.xl,null,$CLJS.eJ,null,$CLJS.HI,null,$CLJS.TK,null,$CLJS.WI,null,$CLJS.gk,null,$CLJS.Sk,null],null),null)),"temporal-extract-units");
wka=$CLJS.TM($CLJS.R.h(SN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Zk,null,$CLJS.sz,null,$CLJS.kl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"datetime-diff-units");wS=$CLJS.TM($CLJS.R.h(SN,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.HJ,null,$CLJS.uI,null,$CLJS.pJ,null],null),null)),"extract-week-modes");
xS=$CLJS.TM($CLJS.R.h(SN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Zk,null,$CLJS.Pj,null,$CLJS.kl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"relative-datetime-unit");xka=$CLJS.VM($CLJS.IN,gO,"valid ISO-8601 datetime string literal");yka=$CLJS.VM($CLJS.IN,gO,"valid ISO-8601 datetime string literal");zka=$CLJS.VM($CLJS.IN,gO,"valid ISO-8601 time string literal");
yS=bO($CLJS.qJ,$CLJS.G(["n",$CLJS.HN($CLJS.G([$CLJS.PM($CLJS.mB),$CLJS.DN])),"unit",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,xS],null)]));Aka=bO($CLJS.hJ,$CLJS.G(["n",$CLJS.DN,"unit",xS]));zS=UN.l($CLJS.G([function(a){return $CLJS.Gb(cO($CLJS.EI,a))},bO($CLJS.EI,$CLJS.G(["t",Date,"unit",$CLJS.uS])),function(a){return $CLJS.Zd(a)instanceof Date},bO($CLJS.EI,$CLJS.G(["date",Date,"unit",tka])),$CLJS.Uu,bO($CLJS.EI,$CLJS.G(["datetime",Date,"unit",$CLJS.uS]))]));
AS=bO($CLJS.YD,$CLJS.G(["time",Date,"unit",uka]));BS=UN.l($CLJS.G([$CLJS.bg(cO,$CLJS.EI),zS,gO,yka,gO,xka,$CLJS.Uu,$CLJS.HN($CLJS.G([Date]))]));Bka=UN.l($CLJS.G([$CLJS.bg(cO,$CLJS.YD),AS,gO,zka,$CLJS.Uu,$CLJS.HN($CLJS.G([Date]))]));CS=$CLJS.HN($CLJS.G([Bka,BS]));
dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof zS?new $CLJS.ud(function(){return zS},$CLJS.K(aia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,aR,$CLJS.Yk,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PQ,"metabase/mbql/schema.cljc",69,$CLJS.EI,1,!0,192,192,$CLJS.yd,"Schema for an `:absolute-datetime` clause.",$CLJS.m(zS)?zS.J:null])):null));return $CLJS.m(a)?a:PQ}(),zS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof yS?new $CLJS.ud(function(){return yS},$CLJS.K(oka,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,
$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",29,$CLJS.qJ,1,174,174,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.qJ,$CLJS.tC,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.sQ,$CLJS.X(Jja,$CLJS.mB),BQ)),$CLJS.Wj,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,PP))],null),$CLJS.yd,"Schema for a valid relative-datetime clause.",$CLJS.m(yS)?yS.J:null])):null));return $CLJS.m(a)?
a:oQ}(),yS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof AS?new $CLJS.ud(function(){return AS},$CLJS.K(hja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,aR,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dS,"metabase/mbql/schema.cljc",27,$CLJS.YD,1,!0,225,225,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.YD,$CLJS.YD,$CLJS.X($CLJS.Il,Tia),$CLJS.Wj,$CLJS.X($CLJS.Il,xia)],null),$CLJS.yd,"Schema for a valid time clause.",$CLJS.m(AS)?AS.J:null])):null));return $CLJS.m(a)?a:dS}(),AS],null)]));Cka=$CLJS.Pf([QN($CLJS.eS),$CLJS.ZN($CLJS.IN),QN($CLJS.sJ),$CLJS.ZN($CLJS.JN),QN($CLJS.AP),$CLJS.ZN($CLJS.KN),QN($CLJS.Wj),$CLJS.ZN($CLJS.uS),QN($CLJS.V),$CLJS.ZN($CLJS.IN),$CLJS.EN,$CLJS.zN]);
DS=bO($CLJS.sl,$CLJS.G(["value",$CLJS.zN,"type-info",$CLJS.ZN(Cka)]));ES=bO($CLJS.zG,$CLJS.G(["expression-name",$CLJS.IN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.RM($CLJS.oe,"map")],null)]));Dka=SN.l($CLJS.G([$CLJS.BI,$CLJS.SK,$CLJS.Pj]));
Eka=function(a){return $CLJS.VM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.VK);c=$CLJS.M.h(c,$CLJS.BI);return $CLJS.F.h(b,$CLJS.BI)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.VM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.VK);c=$CLJS.M.h(c,$CLJS.SK);return $CLJS.F.h(b,$CLJS.SK)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.Pf([$CLJS.VK,Dka,QN($CLJS.BI),$CLJS.LN,QN($CLJS.SK),$CLJS.VM($CLJS.CN,$CLJS.$f($CLJS.ef),
"bin width must be \x3e\x3d 0."),$CLJS.EN,$CLJS.zN])));$CLJS.FS=function FS(a){switch(arguments.length){case 1:return FS.g(arguments[0]);case 2:return FS.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.FS.g=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.CG);b=$CLJS.M.h(b,$CLJS.OQ);return $CLJS.FS.h(a,b)};
$CLJS.FS.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.NH(a,$CLJS.NI)?rS:$CLJS.NH(a,$CLJS.WK)?sS:$CLJS.NH(a,$CLJS.RJ)?tS:null:null;return $CLJS.m(a)?$CLJS.Ie(a,b):!0};$CLJS.FS.v=2;
var GS=$CLJS.VM($CLJS.VM($CLJS.Pf([QN($CLJS.CG),$CLJS.ZN($CLJS.JN),QN($CLJS.UO),$CLJS.ZN($CLJS.HN($CLJS.G([$CLJS.LN,$CLJS.IN]))),QN($CLJS.OQ),$CLJS.ZN($CLJS.uS),QN($CLJS.kQ),$CLJS.ZN($CLJS.IN),QN($CLJS.NR),$CLJS.ZN(Eka),$CLJS.EN,$CLJS.zN]),$CLJS.FS,"Invalid :temporal-unit for the specified :base-type."),$CLJS.$f($CLJS.VK),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),HS=function(a){return $CLJS.VM(a,function(b){$CLJS.J(b,0,null);
var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.CG);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(bO($CLJS.CQ,$CLJS.G(["id-or-name",$CLJS.HN($CLJS.G([$CLJS.LN,$CLJS.IN])),"options",$CLJS.ZN(XN(new $CLJS.ud(function(){return GS},nka,$CLJS.Sh([$CLJS.rm,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[!0,$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Mha,"metabase/mbql/schema.cljc",28,1,383,383,$CLJS.yd,null,$CLJS.m(GS)?GS.J:null]))))])));$CLJS.VM(HS,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ae(a)},"Must be a :field with an integer Field ID.");
IS=dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof ES?new $CLJS.ud(function(){return ES},$CLJS.K(gia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.PO,"metabase/mbql/schema.cljc",60,$CLJS.zG,1,318,318,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.zG,$CLJS.iS,$CLJS.X($CLJS.Il,MR),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,$CLJS.X($CLJS.nQ,$CLJS.ek,"map")))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(ES)?ES.J:null])):null));return $CLJS.m(a)?a:$CLJS.PO}(),ES],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof HS?
new $CLJS.ud(function(){return HS},$CLJS.K(qia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.bl,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],["0.39.0",$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GO,"metabase/mbql/schema.cljc",51,$CLJS.CQ,1,429,429,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(HS)?HS.J:null])):null));return $CLJS.m(a)?
a:GO}(),HS],null)]));$CLJS.JS=XN(new $CLJS.ud(function(){return IS},Cja,$CLJS.Sh([$CLJS.rm,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[!0,$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Hja,"metabase/mbql/schema.cljc",22,1,445,445,$CLJS.yd,null,$CLJS.m(IS)?IS.J:null])));
Fka=bO($CLJS.LP,$CLJS.G(["aggregation-clause-index",$CLJS.DN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.RM($CLJS.oe,"map")],null)]));$CLJS.KS=VN($CLJS.bg(cO,$CLJS.LP),Fka,$CLJS.JS);LS=new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.Su,null,$CLJS.FK,null,$CLJS.$H,null,$CLJS.UK,null,$CLJS.nI,null,$CLJS.PJ,null,$CLJS.YI,null,$CLJS.bv,null,$CLJS.AK,null,$CLJS.NJ,null,$CLJS.XK,null],null),null);
NS=UN.l($CLJS.G([$CLJS.Jb,$CLJS.AN,$CLJS.bg(cO,LS),XN(new $CLJS.ud(function(){return MS},aS,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(MS)?MS.J:null]))),$CLJS.bg(cO,$CLJS.sl),DS,$CLJS.Uu,$CLJS.JS]));
OS=new $CLJS.ni(null,new $CLJS.k(null,26,[$CLJS.iI,null,$CLJS.RK,null,$CLJS.pm,null,$CLJS.nK,null,$CLJS.fK,null,$CLJS.nJ,null,$CLJS.yu,null,$CLJS.YH,null,$CLJS.vK,null,$CLJS.bK,null,$CLJS.PJ,null,$CLJS.OK,null,$CLJS.iK,null,$CLJS.UJ,null,$CLJS.lI,null,$CLJS.CI,null,$CLJS.om,null,$CLJS.sI,null,$CLJS.YI,null,$CLJS.XH,null,$CLJS.iD,null,$CLJS.DI,null,$CLJS.$J,null,$CLJS.gJ,null,$CLJS.QK,null,$CLJS.FJ,null],null),null);
PS=new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.wm,null,$CLJS.mm,null,$CLJS.Ck,null,$CLJS.Uk,null,$CLJS.vm,null,$CLJS.BK,null,$CLJS.Lj,null,$CLJS.jm,null,$CLJS.km,null],null),null);QS=new $CLJS.ni(null,new $CLJS.k(null,17,[$CLJS.rk,null,$CLJS.yK,null,$CLJS.dK,null,$CLJS.LJ,null,$CLJS.MK,null,$CLJS.PK,null,$CLJS.LI,null,$CLJS.lJ,null,$CLJS.mO,null,$CLJS.PI,null,$CLJS.tK,null,$CLJS.XJ,null,$CLJS.am,null,$CLJS.kD,null,$CLJS.BJ,null,$CLJS.ZP,null,$CLJS.rK,null],null),null);
RS=new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.CJ,null,$CLJS.tI,null,$CLJS.KK,null,$CLJS.om,null,$CLJS.cK,null],null),null);
US=UN.l($CLJS.G([$CLJS.Eb,$CLJS.CN,$CLJS.bg(cO,OS),XN(new $CLJS.ud(function(){return SS},sR,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(SS)?SS.J:null]))),$CLJS.bg(cO,QS),XN(new $CLJS.ud(function(){return $CLJS.TS},wO,$CLJS.Sh([$CLJS.Ek,
$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.TS)?$CLJS.TS.J:null]))),$CLJS.bg(cO,$CLJS.sl),DS,$CLJS.Uu,$CLJS.JS]));
WS=UN.l($CLJS.G([$CLJS.bg(cO,QS),XN(new $CLJS.ud(function(){return $CLJS.TS},wO,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.TS)?$CLJS.TS.J:null]))),$CLJS.bg(cO,$CLJS.sl),DS,$CLJS.bg(cO,RS),XN(new $CLJS.ud(function(){return $CLJS.VS},
zP,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.VS)?$CLJS.VS.J:null]))),$CLJS.Uu,$CLJS.HN($CLJS.G([BS,$CLJS.JS]))]));
YS=UN.l($CLJS.G([$CLJS.Eb,$CLJS.CN,$CLJS.ve,$CLJS.BN,$CLJS.bg(cO,PS),XN(new $CLJS.ud(function(){return XS},Zha,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hia,"metabase/mbql/schema.cljc",27,1,523,!0,523,$CLJS.yd,null,$CLJS.m(XS)?XS.J:null]))),$CLJS.bg(cO,OS),XN(new $CLJS.ud(function(){return SS},
sR,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(SS)?SS.J:null]))),$CLJS.bg(cO,RS),XN(new $CLJS.ud(function(){return $CLJS.VS},zP,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],
[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.VS)?$CLJS.VS.J:null]))),$CLJS.Jb,$CLJS.AN,$CLJS.bg(cO,LS),XN(new $CLJS.ud(function(){return MS},aS,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(MS)?MS.J:null]))),$CLJS.bg(cO,$CLJS.sl),DS,$CLJS.Uu,$CLJS.JS]));ZS=VN($CLJS.bg(cO,$CLJS.hJ),Aka,US);Gka=VN($CLJS.Eb,$CLJS.LN,US);$S=bO($CLJS.PJ,$CLJS.G(["a",YS,"b",YS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,YS],null)]));aT=bO($CLJS.$H,$CLJS.G(["s",NS,"start",Gka,"length",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,US],null)]));bT=bO($CLJS.iD,$CLJS.G(["s",NS]));cT=bO($CLJS.AK,$CLJS.G(["s",NS]));
dT=bO($CLJS.XK,$CLJS.G(["s",NS]));eT=bO($CLJS.FK,$CLJS.G(["s",NS]));fT=bO($CLJS.Su,$CLJS.G(["s",NS]));gT=bO($CLJS.bv,$CLJS.G(["s",NS]));hT=bO($CLJS.UK,$CLJS.G(["s",NS,"match",$CLJS.AN,"replacement",$CLJS.AN]));iT=bO($CLJS.nI,$CLJS.G(["a",NS,"b",NS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,NS],null)]));jT=bO($CLJS.NJ,$CLJS.G(["s",NS,"pattern",$CLJS.AN]));kT=bO($CLJS.om,$CLJS.G(["x",ZS,"y",ZS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,ZS],null)]));
lT=bO($CLJS.yu,$CLJS.G(["x",US,"y",ZS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,ZS],null)]));mT=bO($CLJS.OK,$CLJS.G(["x",US,"y",US,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,US],null)]));nT=bO($CLJS.pm,$CLJS.G(["x",US,"y",US,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,US],null)]));oT=bO($CLJS.FJ,$CLJS.G(["x",US]));pT=bO($CLJS.iI,$CLJS.G(["x",US]));qT=bO($CLJS.UJ,$CLJS.G(["x",US]));rT=bO($CLJS.sI,$CLJS.G(["x",US]));sT=bO($CLJS.lI,$CLJS.G(["x",US,"y",US]));tT=bO($CLJS.nJ,$CLJS.G(["x",US]));
uT=bO($CLJS.YH,$CLJS.G(["x",US]));vT=bO($CLJS.QK,$CLJS.G(["x",US]));SS=XN(new $CLJS.ud(function(){return wT},dia,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Wja,"metabase/mbql/schema.cljc",28,1,664,!0,664,$CLJS.yd,null,$CLJS.m(wT)?wT.J:null])));
xT=bO($CLJS.gJ,$CLJS.G(["datetime-x",WS,"datetime-y",WS,"unit",wka]));yT=bO($CLJS.nK,$CLJS.G(["datetime",WS,"unit",vka,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,wS],null)]));zT=bO($CLJS.DI,$CLJS.G(["date",WS]));AT=bO($CLJS.RK,$CLJS.G(["date",WS]));BT=bO($CLJS.XH,$CLJS.G(["date",WS]));CT=bO($CLJS.bK,$CLJS.G(["date",WS,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,wS],null)]));DT=bO($CLJS.$J,$CLJS.G(["date",WS]));ET=bO($CLJS.iK,$CLJS.G(["date",WS]));FT=bO($CLJS.vK,$CLJS.G(["datetime",WS]));
GT=bO($CLJS.fK,$CLJS.G(["datetime",WS]));HT=bO($CLJS.CI,$CLJS.G(["datetime",WS]));IT=bO($CLJS.tI,$CLJS.G(["datetime",WS,"to",$CLJS.vS,"from",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.vS],null)]));JT=$CLJS.TM($CLJS.R.h(SN,new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.Tj,null,$CLJS.wz,null,$CLJS.vz,null,$CLJS.Zk,null,$CLJS.sz,null,$CLJS.kl,null,$CLJS.Jj,null,$CLJS.tz,null,$CLJS.uz,null],null),null)),"arithmetic-datetime-unit");KT=bO($CLJS.cK,$CLJS.G(["datetime",WS,"amount",US,"unit",JT]));
LT=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return bO(arguments[0],1<b.length?new $CLJS.z(b.slice(1),0,null):null)}($CLJS.CJ);MT=bO($CLJS.KK,$CLJS.G(["datetime",WS,"amount",US,"unit",JT]));
NT=dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof kT?new $CLJS.ud(function(){return kT},$CLJS.K(IQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CP,"metabase/mbql/schema.cljc",51,$CLJS.om,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.om,$CLJS.IG,$CLJS.X($CLJS.Il,LQ),LR,$CLJS.X($CLJS.Il,LQ),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,LQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(kT)?kT.J:null])):null));return $CLJS.m(a)?a:CP}(),kT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof KT?new $CLJS.ud(function(){return KT},
$CLJS.K(Ria,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[gP,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",67,$CLJS.cK,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.cK,$CLJS.AJ,$CLJS.X($CLJS.Il,mS),mR,$CLJS.X($CLJS.Il,
pR),$CLJS.Wj,$CLJS.X($CLJS.Il,yR)],null),$CLJS.yd,"Schema for a valid datetime-add clause.",$CLJS.m(KT)?KT.J:null])):null));return $CLJS.m(a)?a:DQ}(),KT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof MT?new $CLJS.ud(function(){return MT},$CLJS.K(zha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,
$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[gP,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lS,"metabase/mbql/schema.cljc",72,$CLJS.KK,1,736,736,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.KK,$CLJS.AJ,$CLJS.X($CLJS.Il,mS),mR,$CLJS.X($CLJS.Il,pR),$CLJS.Wj,$CLJS.X($CLJS.Il,yR)],null),$CLJS.yd,"Schema for a valid datetime-subtract clause.",$CLJS.m(MT)?MT.J:null])):null));return $CLJS.m(a)?
a:lS}(),MT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof IT?new $CLJS.ud(function(){return IT},$CLJS.K(Tja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.tI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WO,"metabase/mbql/schema.cljc",71,$CLJS.tI,1,719,719,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.tI,$CLJS.AJ,$CLJS.X($CLJS.Il,mS),Hia,$CLJS.X($CLJS.Il,OR),Eha,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,OR))],null),$CLJS.yd,"Schema for a valid convert-timezone clause.",$CLJS.m(IT)?IT.J:null])):null));return $CLJS.m(a)?a:WO}(),IT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof LT?new $CLJS.ud(function(){return LT},
$CLJS.K(tja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.CJ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cQ,"metabase/mbql/schema.cljc",45,$CLJS.CJ,1,734,734,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.CJ],null),$CLJS.yd,"Schema for a valid now clause.",
$CLJS.m(LT)?LT.J:null])):null));return $CLJS.m(a)?a:cQ}(),LT],null)]));$CLJS.VS=XN(new $CLJS.ud(function(){return NT},Dia,$CLJS.Sh([$CLJS.rm,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[!0,$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Iia,"metabase/mbql/schema.cljc",35,1,741,741,$CLJS.yd,null,$CLJS.m(NT)?NT.J:null])));
MS=XN(new $CLJS.ud(function(){return OT},Zja,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Uia,"metabase/mbql/schema.cljc",27,1,748,!0,748,$CLJS.yd,null,$CLJS.m(OT)?OT.J:null])));
QT=bO($CLJS.Lj,$CLJS.G(["first-clause",XN(new $CLJS.ud(function(){return $CLJS.PT},zQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.PT)?$CLJS.PT.J:null]))),"second-clause",XN(new $CLJS.ud(function(){return $CLJS.PT},zQ,$CLJS.Sh([$CLJS.Ek,
$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.PT)?$CLJS.PT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,XN(new $CLJS.ud(function(){return $CLJS.PT},zQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,
$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.PT)?$CLJS.PT.J:null])))],null)]));
RT=bO($CLJS.Ck,$CLJS.G(["first-clause",XN(new $CLJS.ud(function(){return $CLJS.PT},zQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.PT)?$CLJS.PT.J:null]))),"second-clause",XN(new $CLJS.ud(function(){return $CLJS.PT},zQ,$CLJS.Sh([$CLJS.Ek,
$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.PT)?$CLJS.PT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,XN(new $CLJS.ud(function(){return $CLJS.PT},zQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,
$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.PT)?$CLJS.PT.J:null])))],null)]));
ST=bO($CLJS.Uk,$CLJS.G(["clause",XN(new $CLJS.ud(function(){return $CLJS.PT},zQ,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.PT)?$CLJS.PT.J:null])))]));TT=VN($CLJS.bg(cO,$CLJS.qJ),yS,$CLJS.JS);
UT=$CLJS.ZN($CLJS.HN($CLJS.G([$CLJS.BN,$CLJS.CN,$CLJS.AN,CS,TT,YS,DS])));VT=VN($CLJS.bg(cO,$CLJS.sl),DS,$CLJS.HN($CLJS.G([$CLJS.CN,$CLJS.AN,CS,YS,TT])));WT=bO($CLJS.jm,$CLJS.G(["field",UT,"value-or-field",UT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,UT],null)]));XT=bO($CLJS.BK,$CLJS.G(["field",UT,"value-or-field",UT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yk,UT],null)]));YT=bO($CLJS.km,$CLJS.G(["field",VT,"value-or-field",VT]));
ZT=bO($CLJS.mm,$CLJS.G(["field",VT,"value-or-field",VT]));$T=bO($CLJS.wm,$CLJS.G(["field",VT,"value-or-field",VT]));aU=bO($CLJS.vm,$CLJS.G(["field",VT,"value-or-field",VT]));bU=bO($CLJS.II,$CLJS.G(["field",VT,"min",VT,"max",VT]));cU=bO($CLJS.TI,$CLJS.G(["lat-field",VT,"lon-field",VT,"lat-max",VT,"lon-min",VT,"lat-min",VT,"lon-max",VT]));dU=bO($CLJS.wI,$CLJS.G(["field",$CLJS.JS]));eU=bO($CLJS.mJ,$CLJS.G(["field",$CLJS.JS]));fU=bO($CLJS.JI,$CLJS.G(["field",$CLJS.JS]));
gU=bO($CLJS.ZI,$CLJS.G(["field",$CLJS.JS]));hU=$CLJS.Pf([QN($CLJS.ZJ),$CLJS.BN]);iU=bO($CLJS.tJ,$CLJS.G(["field",NS,"string-or-field",NS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,hU],null)]));jU=bO($CLJS.lK,$CLJS.G(["field",NS,"string-or-field",NS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,hU],null)]));kU=bO($CLJS.JJ,$CLJS.G(["field",NS,"string-or-field",NS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,hU],null)]));
lU=bO($CLJS.uJ,$CLJS.G(["field",NS,"string-or-field",NS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,hU],null)]));Hka=$CLJS.Pf([QN($CLJS.wK),$CLJS.BN]);mU=bO($CLJS.pK,$CLJS.G(["field",$CLJS.JS,"n",$CLJS.HN($CLJS.G([$CLJS.DN,SN.l($CLJS.G([$CLJS.mB,$CLJS.oD,$CLJS.QI]))])),"unit",xS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Hka],null)]));nU=bO($CLJS.mI,$CLJS.G(["segment-id",$CLJS.HN($CLJS.G([$CLJS.LN,$CLJS.IN]))]));
XS=XN(new $CLJS.ud(function(){return oU},Fia,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Mia,"metabase/mbql/schema.cljc",28,1,883,!0,883,$CLJS.yd,null,$CLJS.m(oU)?oU.J:null])));
oU=dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof QT?new $CLJS.ud(function(){return QT},$CLJS.K(IR,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,ZO,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),uQ,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),eP,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(QT)?QT.J:null])):null));return $CLJS.m(a)?a:QP}(),QT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==
typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof RT?new $CLJS.ud(function(){return RT},$CLJS.K(xQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",14,$CLJS.Ck,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Ck,ZO,$CLJS.X($CLJS.Il,
$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),uQ,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),eP,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(RT)?RT.J:null])):null));return $CLJS.m(a)?a:AQ}(),RT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof ST?new $CLJS.ud(function(){return ST},$CLJS.K(ZQ,new $CLJS.k(null,
1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.YL,"metabase/mbql/schema.cljc",15,$CLJS.Uk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Uk,$CLJS.hS,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(ST)?ST.J:
null])):null));return $CLJS.m(a)?a:$CLJS.YL}(),ST],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof YT?new $CLJS.ud(function(){return YT},$CLJS.K(GQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JP,"metabase/mbql/schema.cljc",13,$CLJS.km,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.km,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(YT)?YT.J:null])):null));return $CLJS.m(a)?a:JP}(),YT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(bS,new $CLJS.k(null,1,
[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",14,$CLJS.wm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.wm,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m($T)?$T.J:null])):
null));return $CLJS.m(a)?a:IP}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},$CLJS.K(EQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KP,"metabase/mbql/schema.cljc",13,$CLJS.mm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.mm,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(ZT)?ZT.J:null])):null));return $CLJS.m(a)?a:KP}(),ZT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(UR,new $CLJS.k(null,1,
[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",14,$CLJS.vm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.vm,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(aU)?aU.J:null])):
null));return $CLJS.m(a)?a:RP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof WT?new $CLJS.ud(function(){return WT},$CLJS.K(FQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.nN,"metabase/mbql/schema.cljc",13,$CLJS.jm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.jm,$CLJS.CQ,$CLJS.X($CLJS.Il,fS),wR,$CLJS.X($CLJS.Il,fS),cR,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,fS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(WT)?WT.J:null])):null));return $CLJS.m(a)?a:$CLJS.nN}(),WT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof XT?new $CLJS.ud(function(){return XT},
$CLJS.K(CO,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",14,$CLJS.BK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.BK,$CLJS.CQ,$CLJS.X($CLJS.Il,fS),wR,$CLJS.X($CLJS.Il,fS),cR,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,fS))],null),
$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(XT)?XT.J:null])):null));return $CLJS.m(a)?a:OO}(),XT],null)]));
pU=UN.l($CLJS.G([$CLJS.bg(cO,RS),$CLJS.VS,$CLJS.bg(cO,OS),SS,$CLJS.bg(cO,LS),MS,$CLJS.bg(cO,PS),XS,$CLJS.Uu,dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof QT?new $CLJS.ud(function(){return QT},$CLJS.K(IR,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,ZO,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),uQ,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),eP,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(QT)?QT.J:null])):null));return $CLJS.m(a)?a:QP}(),QT],
null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof RT?new $CLJS.ud(function(){return RT},$CLJS.K(xQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AQ,"metabase/mbql/schema.cljc",14,$CLJS.Ck,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Ck,ZO,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),uQ,$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))),eP,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(RT)?RT.J:null])):null));return $CLJS.m(a)?a:AQ}(),RT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==
typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof ST?new $CLJS.ud(function(){return ST},$CLJS.K(ZQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.YL,"metabase/mbql/schema.cljc",15,$CLJS.Uk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Uk,$CLJS.hS,
$CLJS.X($CLJS.Il,$CLJS.X(SO,$CLJS.X($CLJS.Ml,VP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(ST)?ST.J:null])):null));return $CLJS.m(a)?a:$CLJS.YL}(),ST],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof WT?new $CLJS.ud(function(){return WT},$CLJS.K(FQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,
FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.nN,"metabase/mbql/schema.cljc",13,$CLJS.jm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.jm,$CLJS.CQ,$CLJS.X($CLJS.Il,fS),wR,$CLJS.X($CLJS.Il,fS),cR,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,fS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(WT)?WT.J:null])):null));return $CLJS.m(a)?a:$CLJS.nN}(),WT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof XT?new $CLJS.ud(function(){return XT},$CLJS.K(CO,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",
14,$CLJS.BK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.BK,$CLJS.CQ,$CLJS.X($CLJS.Il,fS),wR,$CLJS.X($CLJS.Il,fS),cR,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,fS))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(XT)?XT.J:null])):null));return $CLJS.m(a)?a:OO}(),XT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof YT?new $CLJS.ud(function(){return YT},$CLJS.K(GQ,new $CLJS.k(null,
1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",13,$CLJS.km,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.km,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(YT)?YT.J:null])):null));
return $CLJS.m(a)?a:JP}(),YT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},$CLJS.K(EQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KP,"metabase/mbql/schema.cljc",13,$CLJS.mm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.mm,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(ZT)?ZT.J:null])):null));return $CLJS.m(a)?a:KP}(),ZT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(bS,new $CLJS.k(null,1,
[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",14,$CLJS.wm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.wm,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m($T)?$T.J:null])):
null));return $CLJS.m(a)?a:IP}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(UR,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),RP,"metabase/mbql/schema.cljc",14,$CLJS.vm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.vm,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),wR,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(aU)?aU.J:null])):null));return $CLJS.m(a)?a:RP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(jja,new $CLJS.k(null,
1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",19,$CLJS.II,1,820,820,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.II,$CLJS.CQ,$CLJS.X($CLJS.Il,$O),$CLJS.rk,$CLJS.X($CLJS.Il,$O),$CLJS.am,$CLJS.X($CLJS.Il,$O)],null),$CLJS.yd,"Schema for a valid between clause.",
$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:EO}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof iU?new $CLJS.ud(function(){return iU},$CLJS.K(mja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AR,"metabase/mbql/schema.cljc",23,$CLJS.tJ,1,843,843,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.tJ,$CLJS.CQ,$CLJS.X($CLJS.Il,vR),NO,$CLJS.X($CLJS.Il,vR),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,YQ))],null),$CLJS.yd,"Schema for a valid starts-with clause.",$CLJS.m(iU)?iU.J:null])):null));return $CLJS.m(a)?a:AR}(),iU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof jU?new $CLJS.ud(function(){return jU},
$CLJS.K(eia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",21,$CLJS.lK,1,844,844,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.lK,$CLJS.CQ,$CLJS.X($CLJS.Il,vR),NO,$CLJS.X($CLJS.Il,vR),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,YQ))],
null),$CLJS.yd,"Schema for a valid ends-with clause.",$CLJS.m(jU)?jU.J:null])):null));return $CLJS.m(a)?a:RO}(),jU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof kU?new $CLJS.ud(function(){return kU},$CLJS.K(via,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",20,$CLJS.JJ,1,845,845,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.JJ,$CLJS.CQ,$CLJS.X($CLJS.Il,vR),NO,$CLJS.X($CLJS.Il,vR),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,YQ))],null),$CLJS.yd,"Schema for a valid contains clause.",$CLJS.m(kU)?kU.J:null])):null));return $CLJS.m(a)?a:aP}(),kU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==
typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof lU?new $CLJS.ud(function(){return lU},$CLJS.K(lka,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",36,$CLJS.uJ,1,848,848,!0,new $CLJS.S(null,
7,5,$CLJS.T,[$CLJS.uJ,$CLJS.CQ,$CLJS.X($CLJS.Il,vR),NO,$CLJS.X($CLJS.Il,vR),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,YQ))],null),$CLJS.yd,"Schema for a valid does-not-contain clause.",$CLJS.m(lU)?lU.J:null])):null));return $CLJS.m(a)?a:lO}(),lU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(Pja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),
$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zR,"metabase/mbql/schema.cljc",26,$CLJS.TI,1,823,823,!0,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.TI,Kha,$CLJS.X($CLJS.Il,$O),qka,$CLJS.X($CLJS.Il,$O),Yja,$CLJS.X($CLJS.Il,$O),Oha,$CLJS.X($CLJS.Il,$O),Rja,$CLJS.X($CLJS.Il,$O),Jha,$CLJS.X($CLJS.Il,$O)],
null),$CLJS.yd,"Schema for a valid inside clause.",$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:zR}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof fU?new $CLJS.ud(function(){return fU},$CLJS.K(wia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",28,$CLJS.JI,1,837,837,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.JI,$CLJS.CQ,$CLJS.X($CLJS.Il,VO)],null),$CLJS.yd,"Schema for a valid is-empty clause.",$CLJS.m(fU)?fU.J:null])):null));return $CLJS.m(a)?a:lQ}(),fU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&
"undefined"!==typeof gU?new $CLJS.ud(function(){return gU},$CLJS.K(zja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",29,$CLJS.ZI,1,838,838,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZI,$CLJS.CQ,$CLJS.X($CLJS.Il,VO)],null),
$CLJS.yd,"Schema for a valid not-empty clause.",$CLJS.m(gU)?gU.J:null])):null));return $CLJS.m(a)?a:RQ}(),gU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(fka,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",27,$CLJS.wI,1,832,832,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wI,$CLJS.CQ,$CLJS.X($CLJS.Il,VO)],null),$CLJS.yd,"Schema for a valid is-null clause.",$CLJS.m(dU)?dU.J:null])):null));return $CLJS.m(a)?a:dP}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&
"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(Jia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",28,$CLJS.mJ,1,833,833,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mJ,$CLJS.CQ,$CLJS.X($CLJS.Il,VO)],null),
$CLJS.yd,"Schema for a valid not-null clause.",$CLJS.m(eU)?eU.J:null])):null));return $CLJS.m(a)?a:IO}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof mU?new $CLJS.ud(function(){return mU},$CLJS.K(Aha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iR,"metabase/mbql/schema.cljc",33,$CLJS.pK,1,868,868,!0,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.pK,$CLJS.CQ,$CLJS.X($CLJS.Il,VO),$CLJS.tC,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.sQ,BQ,$CLJS.X(rka,$CLJS.mB,$CLJS.oD,$CLJS.QI))),$CLJS.Wj,$CLJS.X($CLJS.Il,PP),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,pja))],null),$CLJS.yd,"Schema for a valid time-interval clause.",$CLJS.m(mU)?mU.J:null])):null));
return $CLJS.m(a)?a:iR}(),mU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof nU?new $CLJS.ud(function(){return nU},$CLJS.K(Via,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SR,"metabase/mbql/schema.cljc",27,$CLJS.mI,1,881,881,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mI,Sha,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.sQ,XO,MR))],null),$CLJS.yd,"Schema for a valid segment clause.",$CLJS.m(nU)?nU.J:null])):null));return $CLJS.m(a)?a:SR}(),nU],null)]))]));
$CLJS.PT=XN(new $CLJS.ud(function(){return pU},hka,$CLJS.Sh([$CLJS.rm,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[!0,$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kia,"metabase/mbql/schema.cljc",23,1,892,892,$CLJS.yd,null,$CLJS.m(pU)?pU.J:null])));
Ika=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PN($CLJS.PT,"pred"),$CLJS.PN(YS,"expr")],null)],null);Jka=$CLJS.Pf([QN($CLJS.Pj),YS]);qU=bO($CLJS.YI,$CLJS.G(["clauses",Ika,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Jka],null)]));
wT=dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof kT?new $CLJS.ud(function(){return kT},$CLJS.K(IQ,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CP,"metabase/mbql/schema.cljc",51,$CLJS.om,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.om,$CLJS.IG,$CLJS.X($CLJS.Il,LQ),LR,$CLJS.X($CLJS.Il,LQ),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,LQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(kT)?kT.J:null])):null));return $CLJS.m(a)?a:CP}(),kT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},
$CLJS.K(fja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DP,"metabase/mbql/schema.cljc",51,$CLJS.yu,1,633,633,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.yu,$CLJS.IG,$CLJS.X($CLJS.Il,pR),LR,
$CLJS.X($CLJS.Il,LQ),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,LQ))],null),$CLJS.yd,"Schema for a valid - clause.",$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:DP}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO?new $CLJS.ud(function(){return mT},$CLJS.K(eja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,bQ,$CLJS.Bk,$CLJS.Yk,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Vu,"metabase/mbql/schema.cljc",$CLJS.OK,1,636,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.OK,$CLJS.IG,$CLJS.X($CLJS.Il,pR),LR,$CLJS.X($CLJS.Il,pR),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,pR))],null),$CLJS.yd,"Schema for a valid / clause.",$CLJS.m(mT)?mT.J:null])):null));return $CLJS.m(a)?a:$CLJS.Vu}(),mT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof nT?new $CLJS.ud(function(){return nT},$CLJS.K(gja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BP,"metabase/mbql/schema.cljc",51,$CLJS.pm,1,638,638,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.pm,$CLJS.IG,$CLJS.X($CLJS.Il,pR),LR,$CLJS.X($CLJS.Il,pR),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,pR))],null),$CLJS.yd,"Schema for a valid * clause.",$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:BP}(),nT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof $S?new $CLJS.ud(function(){return $S},
$CLJS.K(eR,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",58,$CLJS.PJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.PJ,QR,$CLJS.X($CLJS.Il,oO),PR,$CLJS.X($CLJS.Il,
oO),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,oO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m($S)?$S.J:null])):null));return $CLJS.m(a)?a:tR}(),$S],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof bT?new $CLJS.ud(function(){return bT},$CLJS.K(yja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,
$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",56,$CLJS.iD,1,603,603,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iD,$CLJS.FD,$CLJS.X($CLJS.Il,vR)],null),$CLJS.yd,"Schema for a valid length clause.",$CLJS.m(bT)?bT.J:null])):null));return $CLJS.m(a)?a:UQ}(),bT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof oT?new $CLJS.ud(function(){return oT},$CLJS.K(fia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$P,"metabase/mbql/schema.cljc",55,$CLJS.FJ,1,640,640,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FJ,$CLJS.IG,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid floor clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:$P}(),oT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof pT?new $CLJS.ud(function(){return pT},$CLJS.K(Yha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),
$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",54,$CLJS.iI,1,643,643,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iI,$CLJS.IG,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid ceil clause.",$CLJS.m(pT)?
pT.J:null])):null));return $CLJS.m(a)?a:yO}(),pT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof qT?new $CLJS.ud(function(){return qT},$CLJS.K(sia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",55,$CLJS.UJ,1,646,646,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.UJ,$CLJS.IG,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid round clause.",$CLJS.m(qT)?qT.J:null])):null));return $CLJS.m(a)?a:SP}(),qT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&
"undefined"!==typeof rT?new $CLJS.ud(function(){return rT},$CLJS.K(mka,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",53,$CLJS.sI,1,649,649,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.sI,$CLJS.IG,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid abs clause.",$CLJS.m(rT)?rT.J:null])):null));return $CLJS.m(a)?a:qQ}(),rT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof sT?new $CLJS.ud(function(){return sT},$CLJS.K(Vja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,
$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[YR,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HR,"metabase/mbql/schema.cljc",69,$CLJS.lI,1,652,652,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.lI,$CLJS.IG,$CLJS.X($CLJS.Il,pR),LR,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid power clause.",$CLJS.m(sT)?sT.J:null])):null));return $CLJS.m(a)?a:HR}(),sT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof tT?new $CLJS.ud(function(){return tT},$CLJS.K(Oia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[YR,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yP,"metabase/mbql/schema.cljc",68,$CLJS.nJ,1,655,655,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nJ,$CLJS.IG,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid sqrt clause.",$CLJS.m(tT)?tT.J:null])):null));return $CLJS.m(a)?a:yP}(),tT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof uT?new $CLJS.ud(function(){return uT},$CLJS.K(Xja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,
$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[YR,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",67,$CLJS.YH,1,658,658,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.YH,$CLJS.IG,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid exp clause.",$CLJS.m(uT)?uT.J:null])):
null));return $CLJS.m(a)?a:UP}(),uT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof vT?new $CLJS.ud(function(){return vT},$CLJS.K(Rha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[YR,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cS,"metabase/mbql/schema.cljc",67,$CLJS.QK,1,661,661,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QK,$CLJS.IG,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid log clause.",$CLJS.m(vT)?vT.J:null])):null));return $CLJS.m(a)?a:cS}(),vT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==
typeof qU?new $CLJS.ud(function(){return qU},$CLJS.K(sO,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",61,$CLJS.YI,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,
[$CLJS.YI,$CLJS.vC,$CLJS.X($CLJS.Il,fP),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,yQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(qU)?qU.J:null])):null));return $CLJS.m(a)?a:gQ}(),qU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof xT?new $CLJS.ud(function(){return xT},$CLJS.K(Uha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,
bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gJ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",65,$CLJS.gJ,1,680,680,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.gJ,lia,$CLJS.X($CLJS.Il,mS),mia,$CLJS.X($CLJS.Il,mS),$CLJS.Wj,$CLJS.X($CLJS.Il,Qja)],null),$CLJS.yd,"Schema for a valid datetime-diff clause.",
$CLJS.m(xT)?xT.J:null])):null));return $CLJS.m(a)?a:rQ}(),xT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof yT?new $CLJS.ud(function(){return yT},$CLJS.K(Dja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JQ,"metabase/mbql/schema.cljc",71,$CLJS.nK,1,685,685,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.nK,$CLJS.AJ,$CLJS.X($CLJS.Il,mS),$CLJS.Wj,$CLJS.X($CLJS.Il,Zia),$CLJS.dk,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,TR))],null),$CLJS.yd,"Schema for a valid temporal-extract clause.",$CLJS.m(yT)?yT.J:null])):null));return $CLJS.m(a)?a:JQ}(),yT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof zT?new $CLJS.ud(function(){return zT},$CLJS.K(Gia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LO,"metabase/mbql/schema.cljc",71,$CLJS.DI,1,691,691,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DI,tQ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-year clause.",$CLJS.m(zT)?zT.J:null])):null));return $CLJS.m(a)?a:LO}(),zT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof AT?new $CLJS.ud(function(){return AT},$CLJS.K(tia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),
$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HQ,"metabase/mbql/schema.cljc",74,$CLJS.RK,1,694,694,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RK,tQ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-quarter clause.",
$CLJS.m(AT)?AT.J:null])):null));return $CLJS.m(a)?a:HQ}(),AT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof BT?new $CLJS.ud(function(){return BT},$CLJS.K(dja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",72,$CLJS.XH,1,697,697,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XH,tQ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-month clause.",$CLJS.m(BT)?BT.J:null])):null));return $CLJS.m(a)?a:WQ}(),BT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==
typeof jO&&"undefined"!==typeof CT?new $CLJS.ud(function(){return CT},$CLJS.K(dka,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",71,$CLJS.bK,1,700,700,
!0,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.bK,tQ,$CLJS.X($CLJS.Il,mS),$CLJS.dk,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,TR))],null),$CLJS.yd,"Schema for a valid get-week clause.",$CLJS.m(CT)?CT.J:null])):null));return $CLJS.m(a)?a:VQ}(),CT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof DT?new $CLJS.ud(function(){return DT},$CLJS.K(Hha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,
$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",70,$CLJS.$J,1,704,704,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$J,tQ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-day clause.",$CLJS.m(DT)?DT.J:
null])):null));return $CLJS.m(a)?a:QQ}(),DT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof ET?new $CLJS.ud(function(){return ET},$CLJS.K(Xia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",78,$CLJS.iK,1,707,707,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iK,tQ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-day-of-week clause.",$CLJS.m(ET)?ET.J:null])):null));return $CLJS.m(a)?a:MQ}(),ET],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&
"undefined"!==typeof FT?new $CLJS.ud(function(){return FT},$CLJS.K(Wia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",71,$CLJS.vK,1,710,710,!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.vK,$CLJS.AJ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-hour clause.",$CLJS.m(FT)?FT.J:null])):null));return $CLJS.m(a)?a:pO}(),FT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof GT?new $CLJS.ud(function(){return GT},$CLJS.K(Ija,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,
FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",73,$CLJS.fK,1,713,713,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fK,$CLJS.AJ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-minute clause.",$CLJS.m(GT)?GT.J:null])):null));return $CLJS.m(a)?a:gS}(),GT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof HT?new $CLJS.ud(function(){return HT},$CLJS.K(Sia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iQ,"metabase/mbql/schema.cljc",73,$CLJS.CI,1,716,716,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CI,$CLJS.AJ,$CLJS.X($CLJS.Il,mS)],null),$CLJS.yd,"Schema for a valid get-second clause.",$CLJS.m(HT)?HT.J:null])):null));return $CLJS.m(a)?a:iQ}(),HT],null)]));
OT=dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof aT?new $CLJS.ud(function(){return aT},$CLJS.K(wja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$Q,"metabase/mbql/schema.cljc",59,$CLJS.$H,1,600,600,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.$H,$CLJS.FD,$CLJS.X($CLJS.Il,vR),$CLJS.SA,$CLJS.X($CLJS.Il,oia),$CLJS.iD,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,pR))],null),$CLJS.yd,"Schema for a valid substring clause.",$CLJS.m(aT)?aT.J:null])):null));return $CLJS.m(a)?a:$Q}(),aT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof cT?new $CLJS.ud(function(){return cT},
$CLJS.K($ja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",54,$CLJS.AK,1,606,606,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AK,$CLJS.FD,$CLJS.X($CLJS.Il,vR)],null),
$CLJS.yd,"Schema for a valid trim clause.",$CLJS.m(cT)?cT.J:null])):null));return $CLJS.m(a)?a:qR}(),cT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof eT?new $CLJS.ud(function(){return eT},$CLJS.K(Yia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,
1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dR,"metabase/mbql/schema.cljc",55,$CLJS.FK,1,612,612,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FK,$CLJS.FD,$CLJS.X($CLJS.Il,vR)],null),$CLJS.yd,"Schema for a valid ltrim clause.",$CLJS.m(eT)?eT.J:null])):null));return $CLJS.m(a)?a:dR}(),eT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&
"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof dT?new $CLJS.ud(function(){return dT},$CLJS.K(xja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",
55,$CLJS.XK,1,609,609,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XK,$CLJS.FD,$CLJS.X($CLJS.Il,vR)],null),$CLJS.yd,"Schema for a valid rtrim clause.",$CLJS.m(dT)?dT.J:null])):null));return $CLJS.m(a)?a:wQ}(),dT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof hT?new $CLJS.ud(function(){return hT},$CLJS.K(Bha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,
$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RR,"metabase/mbql/schema.cljc",57,$CLJS.UK,1,621,621,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.UK,$CLJS.FD,$CLJS.X($CLJS.Il,vR),Pha,$CLJS.X($CLJS.Il,$CLJS.OP),$CLJS.AF,$CLJS.X($CLJS.Il,$CLJS.OP)],null),$CLJS.yd,"Schema for a valid replace clause.",
$CLJS.m(hT)?hT.J:null])):null));return $CLJS.m(a)?a:RR}(),hT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof gT?new $CLJS.ud(function(){return gT},$CLJS.K(Aja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pS,"metabase/mbql/schema.cljc",55,$CLJS.bv,1,618,618,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bv,$CLJS.FD,$CLJS.X($CLJS.Il,vR)],null),$CLJS.yd,"Schema for a valid lower clause.",$CLJS.m(gT)?gT.J:null])):null));return $CLJS.m(a)?a:pS}(),gT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==
typeof jO&&"undefined"!==typeof fT?new $CLJS.ud(function(){return fT},$CLJS.K(cka,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DR,"metabase/mbql/schema.cljc",55,$CLJS.Su,1,615,615,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Su,$CLJS.FD,$CLJS.X($CLJS.Il,vR)],null),$CLJS.yd,"Schema for a valid upper clause.",$CLJS.m(fT)?fT.J:null])):null));return $CLJS.m(a)?a:DR}(),fT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof iT?new $CLJS.ud(function(){return iT},$CLJS.K(oja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,
FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",56,$CLJS.nI,1,624,624,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.nI,QR,$CLJS.X($CLJS.Il,vR),PR,$CLJS.X($CLJS.Il,vR),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,vR))],null),$CLJS.yd,"Schema for a valid concat clause.",$CLJS.m(iT)?iT.J:null])):
null));return $CLJS.m(a)?a:qP}(),iT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof jT?new $CLJS.ud(function(){return jT},$CLJS.K(vja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.NQ,"null",$CLJS.mfa,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",74,$CLJS.NJ,1,627,627,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.NJ,$CLJS.FD,$CLJS.X($CLJS.Il,vR),$CLJS.zF,$CLJS.X($CLJS.Il,$CLJS.OP)],null),$CLJS.yd,"Schema for a valid regex-match-first clause.",$CLJS.m(jT)?jT.J:null])):null));return $CLJS.m(a)?a:JO}(),jT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&
"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof $S?new $CLJS.ud(function(){return $S},$CLJS.K(eR,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.NQ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",
58,$CLJS.PJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.PJ,QR,$CLJS.X($CLJS.Il,oO),PR,$CLJS.X($CLJS.Il,oO),YO,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.uk,oO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m($S)?$S.J:null])):null));return $CLJS.m(a)?a:tR}(),$S],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof qU?new $CLJS.ud(function(){return qU},$CLJS.K(sO,new $CLJS.k(null,
1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",61,$CLJS.YI,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.YI,$CLJS.vC,$CLJS.X($CLJS.Il,fP),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,
yQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(qU)?qU.J:null])):null));return $CLJS.m(a)?a:gQ}(),qU],null)]));$CLJS.rU=UN.l($CLJS.G([$CLJS.bg(cO,OS),SS,$CLJS.bg(cO,LS),MS,$CLJS.bg(cO,PS),XS,$CLJS.bg(cO,RS),$CLJS.VS,$CLJS.bg(cO,$CLJS.YI),qU,$CLJS.Uu,$CLJS.JS]));sU=bO($CLJS.kD,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.JS],null)]));tU=bO($CLJS.LJ,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.JS],null)]));
uU=bO($CLJS.BJ,$CLJS.G(["field-or-expression",$CLJS.rU]));vU=bO($CLJS.PK,$CLJS.G(["field-or-expression",$CLJS.rU]));wU=bO($CLJS.LI,$CLJS.G(["field-or-expression",$CLJS.rU]));xU=bO($CLJS.rK,$CLJS.G(["field-or-expression",$CLJS.rU]));yU=bO($CLJS.rk,$CLJS.G(["field-or-expression",$CLJS.rU]));zU=bO($CLJS.am,$CLJS.G(["field-or-expression",$CLJS.rU]));AU=bO($CLJS.MK,$CLJS.G(["field-or-expression",$CLJS.rU,"pred",$CLJS.PT]));BU=bO($CLJS.dK,$CLJS.G(["pred",$CLJS.PT]));CU=bO($CLJS.XJ,$CLJS.G(["pred",$CLJS.PT]));
DU=bO($CLJS.yK,$CLJS.G(["field-or-expression",$CLJS.rU]));EU=bO($CLJS.PI,$CLJS.G(["field-or-expression",$CLJS.rU]));FU=bO($CLJS.tK,$CLJS.G(["field-or-expression",$CLJS.rU]));GU=bO($CLJS.lJ,$CLJS.G(["field-or-expression",$CLJS.rU,"percentile",US]));HU=bO($CLJS.ZP,$CLJS.G(["metric-id",$CLJS.HN($CLJS.G([$CLJS.LN,$CLJS.IN]))]));
IU=VN($CLJS.bg(cO,OS),SS,dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof uU?new $CLJS.ud(function(){return uU},$CLJS.K(uja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",60,$CLJS.BJ,1,955,955,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.BJ,ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid avg clause.",$CLJS.m(uU)?uU.J:null])):null));return $CLJS.m(a)?a:dQ}(),uU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof vU?
new $CLJS.ud(function(){return vU},$CLJS.K(Aia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KR,"metabase/mbql/schema.cljc",64,$CLJS.PK,1,956,956,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PK,
ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid cum-sum clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:KR}(),vU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof wU?new $CLJS.ud(function(){return wU},$CLJS.K(ria,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],
[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",65,$CLJS.LI,1,957,957,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LI,ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid distinct clause.",$CLJS.m(wU)?wU.J:null])):null));return $CLJS.m(a)?a:hQ}(),wU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof DU?new $CLJS.ud(function(){return DU},$CLJS.K(Sja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.jK,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rO,"metabase/mbql/schema.cljc",76,$CLJS.yK,1,971,971,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yK,ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid stddev clause.",$CLJS.m(DU)?DU.J:null])):null));return $CLJS.m(a)?a:rO}(),DU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof xU?new $CLJS.ud(function(){return xU},$CLJS.K(ija,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,
$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",60,$CLJS.rK,1,958,958,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rK,ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid sum clause.",$CLJS.m(xU)?xU.J:null])):
null));return $CLJS.m(a)?a:BO}(),xU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof yU?new $CLJS.ud(function(){return yU},$CLJS.K(Lha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ul,"metabase/mbql/schema.cljc",60,$CLJS.rk,1,959,959,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rk,ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid min clause.",$CLJS.m(yU)?yU.J:null])):null));return $CLJS.m(a)?a:$CLJS.ul}(),yU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&
"undefined"!==typeof zU?new $CLJS.ud(function(){return zU},$CLJS.K(Gha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.em,"metabase/mbql/schema.cljc",60,$CLJS.am,1,960,960,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.am,ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid max clause.",$CLJS.m(zU)?zU.J:null])):null));return $CLJS.m(a)?a:$CLJS.em}(),zU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof HU?new $CLJS.ud(function(){return HU},$CLJS.K(nia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,
$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",26,$CLJS.ZP,1,989,989,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZP,Bia,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.sQ,XO,MR))],null),$CLJS.yd,"Schema for a valid metric clause.",$CLJS.m(HU)?HU.J:null])):null));return $CLJS.m(a)?a:pP}(),HU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==
typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(Qia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
jP,"metabase/mbql/schema.cljc",62,$CLJS.XJ,1,968,968,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XJ,$CLJS.Bl,$CLJS.X($CLJS.Il,VP)],null),$CLJS.yd,"Schema for a valid share clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:jP}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof BU?new $CLJS.ud(function(){return BU},$CLJS.K(Nha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,
$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",68,$CLJS.dK,1,965,965,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dK,$CLJS.Bl,$CLJS.X($CLJS.Il,VP)],null),$CLJS.yd,"Schema for a valid count-where clause.",$CLJS.m(BU)?
BU.J:null])):null));return $CLJS.m(a)?a:zO}(),BU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof AU?new $CLJS.ud(function(){return AU},$CLJS.K(kka,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",66,$CLJS.MK,1,962,962,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.MK,ER,$CLJS.X($CLJS.Il,xR),$CLJS.Bl,$CLJS.X($CLJS.Il,VP)],null),$CLJS.yd,"Schema for a valid sum-where clause.",$CLJS.m(AU)?AU.J:null])):null));return $CLJS.m(a)?a:eQ}(),AU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&
"undefined"!==typeof jO&&"undefined"!==typeof qU?new $CLJS.ud(function(){return qU},$CLJS.K(sO,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",61,$CLJS.YI,
1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.YI,$CLJS.vC,$CLJS.X($CLJS.Il,fP),$CLJS.Ok,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,yQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(qU)?qU.J:null])):null));return $CLJS.m(a)?a:gQ}(),qU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof FU?new $CLJS.ud(function(){return FU},$CLJS.K(Lja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,
$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.DJ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JR,"metabase/mbql/schema.cljc",68,$CLJS.tK,1,977,977,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tK,ER,$CLJS.X($CLJS.Il,xR)],null),$CLJS.yd,"Schema for a valid median clause.",$CLJS.m(FU)?FU.J:null])):
null));return $CLJS.m(a)?a:JR}(),FU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof GU?new $CLJS.ud(function(){return GU},$CLJS.K(cja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.DJ,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",72,$CLJS.lJ,1,980,980,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.lJ,ER,$CLJS.X($CLJS.Il,xR),$CLJS.lJ,$CLJS.X($CLJS.Il,pR)],null),$CLJS.yd,"Schema for a valid percentile clause.",$CLJS.m(GU)?GU.J:null])):null));return $CLJS.m(a)?a:nR}(),GU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&
"undefined"!==typeof jO&&"undefined"!==typeof EU?new $CLJS.ud(function(){return EU},$CLJS.K(Xha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",77,$CLJS.PI,1,974,974,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PI,ER,$CLJS.X($CLJS.Il,
xR)],null),$CLJS.yd,"Schema for a valid var clause.",$CLJS.m(EU)?EU.J:null])):null));return $CLJS.m(a)?a:qO}(),EU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof tU?new $CLJS.ud(function(){return tU},$CLJS.K(Iha,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",74,$CLJS.LJ,1,945,945,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LJ,$CLJS.CQ,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,VO))],null),$CLJS.yd,"Schema for a valid cum-count clause.",$CLJS.m(tU)?tU.J:null])):null));return $CLJS.m(a)?a:WP}(),tU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof sU?new $CLJS.ud(function(){return sU},$CLJS.K(pia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([aQ,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FR,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.eI,"null"],null),null),$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.ck,"metabase/mbql/schema.cljc",70,$CLJS.kD,1,944,944,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kD,$CLJS.CQ,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.wN,VO))],null),$CLJS.yd,"Schema for a valid count clause.",$CLJS.m(sU)?sU.J:null])):null));return $CLJS.m(a)?a:$CLJS.ck}(),sU],null)])));
JU=XN(new $CLJS.ud(function(){return IU},bka,$CLJS.Sh([$CLJS.rm,$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[!0,$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eka,"metabase/mbql/schema.cljc",35,1,995,995,$CLJS.yd,null,$CLJS.m(IU)?IU.J:null])));Kka=$CLJS.Pf([QN($CLJS.V),$CLJS.IN,QN($CLJS.SI),$CLJS.IN,$CLJS.EN,$CLJS.zN]);
$CLJS.KU=bO($CLJS.mO,$CLJS.G(["aggregation",JU,"options",Kka]));$CLJS.TS=VN($CLJS.bg(cO,$CLJS.mO),$CLJS.KU,JU);LU=bO($CLJS.EJ,$CLJS.G(["field",$CLJS.KS]));MU=bO($CLJS.rJ,$CLJS.G(["field",$CLJS.KS]));
$CLJS.NU=dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof LU?new $CLJS.ud(function(){return LU},$CLJS.K(Oja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HP,"metabase/mbql/schema.cljc",15,$CLJS.EJ,1,1033,1033,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EJ,$CLJS.CQ,$CLJS.X($CLJS.Il,kP)],null),$CLJS.yd,"Schema for a valid asc clause.",$CLJS.m(LU)?LU.J:null])):null));return $CLJS.m(a)?a:HP}(),LU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof MU?new $CLJS.ud(function(){return MU},$CLJS.K(zia,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),
$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",16,$CLJS.rJ,1,1034,1034,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.rJ,$CLJS.CQ,$CLJS.X($CLJS.Il,kP)],null),$CLJS.yd,"Schema for a valid desc clause.",$CLJS.m(MU)?MU.J:null])):null));return $CLJS.m(a)?a:fR}(),MU],null)]));
SN.l($CLJS.G([$CLJS.kI,$CLJS.OI,$CLJS.bI,$CLJS.mz,$CLJS.GG,tQ]));OU=$CLJS.Pf([QN($CLJS.Hk),$CLJS.IN,$CLJS.V,$CLJS.IN,$CLJS.SI,$CLJS.IN,$CLJS.EN,$CLJS.zN]);Lka=$CLJS.jn.l($CLJS.G([OU,$CLJS.Pf([$CLJS.Xk,$CLJS.PM($CLJS.kI),$CLJS.dI,$CLJS.IN,lja,$CLJS.LN,QN($CLJS.KQ),$CLJS.LN])]));Mka=$CLJS.jn.l($CLJS.G([OU,new $CLJS.k(null,2,[$CLJS.Xk,$CLJS.PM($CLJS.OI),$CLJS.HK,$CLJS.LN],null)]));PU=$CLJS.jn.l($CLJS.G([OU,$CLJS.Pf([QN($CLJS.Pj),$CLJS.zN,QN(jia),$CLJS.BN])]));
Nka=$CLJS.jn.l($CLJS.G([PU,$CLJS.Pf([$CLJS.Xk,$CLJS.PM($CLJS.bI),$CLJS.bI,HS,$CLJS.bJ,XN(new $CLJS.ud(function(){return QU},aka,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pka,"metabase/mbql/schema.cljc",34,1,1130,!0,1130,$CLJS.yd,null,$CLJS.m(QU)?QU.J:null]))),QN($CLJS.Ok),$CLJS.Pf([$CLJS.EN,
$CLJS.zN])])]));Oka=$CLJS.R.h(SN,new $CLJS.ni(null,new $CLJS.k(null,4,[tQ,null,$CLJS.mz,null,$CLJS.kk,null,$CLJS.GG,null],null),null));Pka=$CLJS.jn.l($CLJS.G([PU,new $CLJS.k(null,1,[$CLJS.Xk,Oka],null)]));Qka=UN.l($CLJS.G([function(a){return $CLJS.F.h($CLJS.Xk.g(a),$CLJS.bI)},Nka,function(a){return $CLJS.F.h($CLJS.Xk.g(a),$CLJS.kI)},Lka,function(a){return $CLJS.F.h($CLJS.Xk.g(a),$CLJS.OI)},Mka,$CLJS.Uu,Pka]));
Rka=$CLJS.VM($CLJS.Pf([$CLJS.IN,Qka]),function(a){return $CLJS.Wf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.V.g(b))},a)},"keys in template tag map must match the :name of their values");RU=$CLJS.Pf([$CLJS.tO,$CLJS.zN,QN($CLJS.gR),Rka,QN(lP),$CLJS.ZN($CLJS.IN),$CLJS.EN,$CLJS.zN]);
TU=VN($CLJS.Pm.h($CLJS.oe,$CLJS.SQ),$CLJS.ON(RU,new $CLJS.k(null,1,[$CLJS.tO,$CLJS.SQ],null)),XN(new $CLJS.ud(function(){return $CLJS.SU},Kia,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Mja,"metabase/mbql/schema.cljc",25,1,1212,!0,1212,$CLJS.yd,null,$CLJS.m($CLJS.SU)?$CLJS.SU.J:null]))));
UU=$CLJS.Pf([$CLJS.V,$CLJS.IN,$CLJS.sJ,$CLJS.JN,kja,$CLJS.IN,QN($CLJS.AP),$CLJS.ZN($CLJS.KN),QN($CLJS.cP),$CLJS.ZN($CLJS.wha),$CLJS.zN,$CLJS.zN]);$CLJS.VU=/^card__[1-9]\d*$/;WU=$CLJS.HN($CLJS.G([$CLJS.LN,$CLJS.VU]));Ska=$CLJS.R.h(SN,new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.GR,null,$CLJS.kO,null,$CLJS.vQ,null,$CLJS.lR,null],null),null));
Tka=$CLJS.VM($CLJS.Sh([$CLJS.EN,QN($CLJS.MP),QN($CLJS.XP),QN($CLJS.uP),QN($CLJS.vO),$CLJS.ZR,QN($CLJS.jR),QN($CLJS.hR),QN($CLJS.VK)],[$CLJS.zN,$CLJS.ZN(new $CLJS.S(null,1,5,$CLJS.T,[UU],null)),WU,$CLJS.IN,$CLJS.TM($CLJS.HN($CLJS.G([SN.l($CLJS.G([$CLJS.QD,$CLJS.vF])),XN(new $CLJS.ud(function(){return XU},Gja,$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,$CLJS.Bk,$CLJS.Yk,bR,$CLJS.xm,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qja,"metabase/mbql/schema.cljc",16,1,1259,!0,1259,$CLJS.yd,null,$CLJS.m(XU)?XU.J:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.PT,TU,$CLJS.ZN($CLJS.LN),Ska]),$CLJS.Pm.h($CLJS.Qm.h($CLJS.XP,$CLJS.jR),$CLJS.$f($CLJS.Pm.h($CLJS.XP,$CLJS.jR))),"Joins must have either a `source-table` or `source-query`, but not both.");
Uka=$CLJS.VM(eO(new $CLJS.S(null,1,5,$CLJS.T,[Tka],null)),function(a){return fO($CLJS.ug($CLJS.Hb,$CLJS.rg.h($CLJS.uP,a)))},"All join aliases must be unique.");XU=$CLJS.TM($CLJS.VM(eO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.JS],null)),fO,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.SU=$CLJS.VM($CLJS.VM($CLJS.Sh([$CLJS.EN,QN($CLJS.WR),QN($CLJS.MP),QN($CLJS.TO),QN($CLJS.XP),QN($CLJS.HO),QN($CLJS.vO),QN($CLJS.LP),QN($CLJS.EP),QN($CLJS.jR),QN($CLJS.qS),QN(TQ),QN($CLJS.NQ)],[$CLJS.zN,$CLJS.VM(eO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.NU],null)),fO,"distinct"),$CLJS.ZN(new $CLJS.S(null,1,5,$CLJS.T,[UU],null)),Uka,WU,$CLJS.PT,XU,eO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.TS],null)),eO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.JS],null)),TU,$CLJS.MN,new $CLJS.k(null,2,[TQ,$CLJS.LN,cia,$CLJS.LN],
null),$CLJS.Pf([$CLJS.IN,$CLJS.rU])]),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jR,$CLJS.XP],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.EP);b=$CLJS.M.h(b,$CLJS.vO);return $CLJS.je($CLJS.Ez.h($CLJS.si(a),$CLJS.si(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
YU=$CLJS.Sh([$CLJS.CR,tQ,iP,bP,NP,kS,xP,$CLJS.jS,$CLJS.mz,wP,uO,uR,$R,$CLJS.DO,VR,hP,$CLJS.TP,vP,$CLJS.Hk,$CLJS.tP,nO,$CLJS.QO,$CLJS.sP,pQ,$CLJS.kk,$CLJS.GG,nP],[new $CLJS.k(null,1,[GP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.CR,null,tQ,null,$CLJS.mz,null,$CLJS.kk,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,tQ,GP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.CR,null,tQ,null,$CLJS.jS,null,$CLJS.TP,null,$CLJS.Hk,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Rl,
$CLJS.OJ,BR,GP,new $CLJS.ni(null,new $CLJS.k(null,1,[iP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,nS,$CLJS.OJ,BR,GP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.CR,null,bP,null,$CLJS.mz,null,$CLJS.Hk,null,nO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Rl,$CLJS.OJ,xO,GP,new $CLJS.ni(null,new $CLJS.k(null,1,[NP,null],null),null)],null),new $CLJS.k(null,1,[GP,new $CLJS.ni(null,new $CLJS.k(null,1,[kS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,nS,$CLJS.OJ,Eja,GP,new $CLJS.ni(null,
new $CLJS.k(null,1,[xP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,tQ,GP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.jS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,nS,GP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.CR,null,bP,null,$CLJS.mz,null,$CLJS.Hk,null,nO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,nS,$CLJS.OJ,xO,GP,new $CLJS.ni(null,new $CLJS.k(null,1,[wP,null],null),null)],null),new $CLJS.k(null,1,[GP,new $CLJS.ni(null,new $CLJS.k(null,1,[uO,null],null),null)],
null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Rl,$CLJS.OJ,BR,GP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.CR,null,kS,null,uO,null,uR,null,vP,null,$CLJS.Hk,null,nO,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Rl,$CLJS.OJ,xO,GP,new $CLJS.ni(null,new $CLJS.k(null,1,[$R,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,tQ,GP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.jS,null,$CLJS.DO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Rl,$CLJS.OJ,xO,GP,new $CLJS.ni(null,
new $CLJS.k(null,1,[VR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,$CLJS.Rl,$CLJS.OJ,xO,GP,new $CLJS.ni(null,new $CLJS.k(null,1,[hP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,tQ,GP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.CR,null,tQ,null,$CLJS.jS,null,$CLJS.TP,null,$CLJS.Hk,null],null),null)],null),new $CLJS.k(null,1,[GP,new $CLJS.ni(null,new $CLJS.k(null,1,[vP,null],null),null)],null),new $CLJS.k(null,1,[GP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Hk,null],null),null)],
null),new $CLJS.k(null,2,[$CLJS.Xk,tQ,GP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.jS,null,$CLJS.tP,null],null),null)],null),new $CLJS.k(null,1,[GP,new $CLJS.ni(null,new $CLJS.k(null,1,[nO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,tQ,GP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.jS,null,$CLJS.QO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,tQ,GP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.jS,null,$CLJS.sP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,nS,$CLJS.OJ,BR,
GP,new $CLJS.ni(null,new $CLJS.k(null,1,[pQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,$CLJS.kk,GP,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.CR,null,$CLJS.Hk,null,$CLJS.kk,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Xk,$CLJS.Rl,GP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.CR,null,kS,null,uO,null,uR,null,vP,null,$CLJS.Hk,null,nO,null,$CLJS.GG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Xk,nS,$CLJS.OJ,xO,GP,new $CLJS.ni(null,new $CLJS.k(null,1,[nP,null],null),null)],null)]);
Vka=$CLJS.R.h(SN,$CLJS.gi(YU));QU=$CLJS.R.h(SN,$CLJS.nf($CLJS.vF,$CLJS.gi(YU)));ZU=bO(Tha,$CLJS.G(["tag-name",$CLJS.HN($CLJS.G([$CLJS.IN,new $CLJS.k(null,1,[$CLJS.Hk,$CLJS.IN],null)]))]));$U=bO($CLJS.bI,$CLJS.G(["target",$CLJS.HN($CLJS.G([$CLJS.JS,ZU]))]));aV=bO(rP,$CLJS.G(["target",ZU]));
Wka=$CLJS.HN($CLJS.G([$CLJS.JS,dO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof $U?new $CLJS.ud(function(){return $U},$CLJS.K(aja,new $CLJS.k(null,1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XQ,"metabase/mbql/schema.cljc",21,$CLJS.bI,1,1557,1557,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bI,$CLJS.ZK,$CLJS.X($CLJS.Il,$CLJS.X($CLJS.sQ,VO,FP))],null),$CLJS.yd,"Schema for a valid dimension clause.",$CLJS.m($U)?$U.J:null])):null));return $CLJS.m(a)?a:XQ}(),$U],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=bQ.g($CLJS.fe("undefined"!==typeof $CLJS.hO&&"undefined"!==typeof iO&&"undefined"!==typeof jO&&"undefined"!==typeof aV?new $CLJS.ud(function(){return aV},$CLJS.K(rja,new $CLJS.k(null,
1,[$CLJS.Tl,!0],null)),$CLJS.Sh([$CLJS.Ek,$CLJS.V,$CLJS.hm,$CLJS.ak,bQ,$CLJS.Bk,$CLJS.Yk,$CLJS.xm,FO,$CLJS.Zj,$CLJS.W,$CLJS.gm],[$CLJS.K(oR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",20,rP,1,1560,1560,new $CLJS.S(null,3,5,$CLJS.T,[rP,$CLJS.ZK,$CLJS.X($CLJS.Il,FP)],null),$CLJS.yd,"Schema for a valid variable clause.",$CLJS.m(aV)?aV.J:null])):null));return $CLJS.m(a)?a:oS}(),
aV],null)]))]));Xka=$CLJS.Pf([$CLJS.Xk,Vka,QN($CLJS.Hk),$CLJS.IN,QN($CLJS.ZK),Wka,QN($CLJS.sl),$CLJS.zN,QN($CLJS.V),$CLJS.IN,QN($ha),$CLJS.IN,QN($CLJS.Pj),$CLJS.zN,$CLJS.EN,$CLJS.zN]);Yka=new $CLJS.S(null,1,5,$CLJS.T,[Xka],null);Zka=$CLJS.Pf([QN(ika),$CLJS.IN,$CLJS.EN,$CLJS.zN]);$ka=$CLJS.VM($CLJS.Pf([QN(fQ),$CLJS.MN,QN(AO),$CLJS.MN,$CLJS.EN,$CLJS.zN]),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,fQ);b=$CLJS.M.h(b,AO);return $CLJS.Gb($CLJS.m(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
ala=$CLJS.Pf([QN(Nja),$CLJS.BN,QN(Vha),$CLJS.BN,QN(Cha),$CLJS.BN,QN(bia),$CLJS.BN,QN(Fja),$CLJS.ZN($CLJS.BN),QN(Fha),$CLJS.ZN($CLJS.BN),QN(Uja),$CLJS.ZN($CLJS.BN),$CLJS.EN,$CLJS.zN]);bla=SN.l($CLJS.G([nja,lP,bja,sja,Nia,Qha,Wha,iia,yia,Bja,ska,Cia,uia]));
cla=$CLJS.Sh([$CLJS.EN,QN(jka),QN(Eia),QN($CLJS.mP),QN(Kja),QN(Lia),QN(Dha),QN(gka),QN($CLJS.HK),QN($CLJS.KO)],[$CLJS.zN,$CLJS.ZN($CLJS.IN),$CLJS.ZN($CLJS.LN),$CLJS.ZN(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Pf([$CLJS.zN,$CLJS.zN])],null)),$CLJS.ZN($CLJS.LN),$CLJS.ZN($CLJS.LN),$CLJS.ZN($CLJS.zN),$CLJS.ZN($CLJS.Pf([$CLJS.zN,$CLJS.zN])),$CLJS.ZN($CLJS.LN),$CLJS.ZN(bla)]);dla=$CLJS.HN($CLJS.G([$CLJS.PM(-1337),$CLJS.LN]));
$CLJS.bV=$CLJS.VM($CLJS.VM($CLJS.VM($CLJS.Sh([$CLJS.EN,QN($CLJS.SQ),QN($CLJS.kR),QN($CLJS.ZD),QN($ia),QN(Pia),$CLJS.Xk,QN($CLJS.tO),$CLJS.KQ,QN($CLJS.MO)],[$CLJS.zN,RU,$CLJS.ZN(Zka),$CLJS.ZN(cla),$CLJS.ZN($ka),$CLJS.ZN(ala),SN.l($CLJS.G([$CLJS.tO,$CLJS.SQ])),$CLJS.SU,dla,Yka]),$CLJS.Pm.h($CLJS.Qm.h($CLJS.SQ,$CLJS.tO),$CLJS.$f($CLJS.Pm.h($CLJS.SQ,$CLJS.tO))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.SQ);var c=$CLJS.M.h(b,$CLJS.tO);
b=$CLJS.M.h(b,$CLJS.Xk);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.$f($CLJS.MP),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$CLJS.$N(a);return function(c){var d=b(c);if($CLJS.m(d))throw $CLJS.zj($CLJS.TL("Value does not match schema: %s",$CLJS.G([$CLJS.aj.l($CLJS.G([d]))])),new $CLJS.k(null,4,[$CLJS.Xk,$CLJS.eha,$CLJS.fm,a,$CLJS.sl,c,$CLJS.Xu,d],null));return c}})($CLJS.bV);