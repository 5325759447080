var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.js");require("./metabase.util.memoize.js");
'use strict';var h4,i4,j4,Lqa,Mqa,Nqa,l4,m4;h4=function(a,b){var c=$CLJS.He,d=g4;for(a=$CLJS.A(a);;)if(null!=a){d=$CLJS.M.j(d,$CLJS.C(a),c);if(c===d)return b;a=$CLJS.D(a)}else return d};i4=function(a){return $CLJS.oh.j($CLJS.oi,$CLJS.Rm.g(function(b){return $CLJS.xf(b)&&$CLJS.ki.h(b,$CLJS.tl)}),$CLJS.gi(a))};j4=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Ko(function(b,c){$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.HG);return new $CLJS.S(null,2,5,$CLJS.T,[c,b],null)}),$CLJS.LP.g(a))};
Lqa=function(a){return $CLJS.dW(a,function(b){return $CLJS.oh.j($CLJS.P,$CLJS.Rm.g(function(c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return $CLJS.xf(d)}),b)})};Mqa=new $CLJS.N("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);Nqa=new $CLJS.N("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.k4=new $CLJS.N("metabase.lib.join","join-alias","metabase.lib.join/join-alias",1166233023);l4=new $CLJS.N(null,"left","left",-399115937);
m4=new $CLJS.N("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.n4=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Rk,$CLJS.CH],null),$CLJS.Rk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.lib.equality","\x3d"),function(f,g){f=$CLJS.BH(f);g=$CLJS.BH(g);return $CLJS.ki.h(f,g)?m4:f},$CLJS.Pj,e,a,b,c,d)}();$CLJS.n4.o(null,m4,function(){return!1});
$CLJS.n4.o(null,$CLJS.yG,function(a,b){var c=i4(a),d=i4(b);return $CLJS.F.h(c,d)&&$CLJS.Wf(function(e){return $CLJS.n4.h($CLJS.M.h(a,e),$CLJS.M.h(b,e))},c)});$CLJS.n4.o(null,$CLJS.wG,function(a,b){var c=$CLJS.F.h($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.A(a),$CLJS.C(c),$CLJS.D(c),c=$CLJS.A(b),$CLJS.C(c),$CLJS.D(c),c=a,a=b;;){c=$CLJS.A(c);b=$CLJS.C(c);var d=$CLJS.D(c);c=b;b=d;a=$CLJS.A(a);d=$CLJS.C(a);a=$CLJS.D(a);c=$CLJS.n4.h(c,d);if($CLJS.m(c)){if(c=$CLJS.je(b))return c;c=b}else return c}else return c});
var g4=null;$CLJS.n4.o(null,$CLJS.AZ,function(a,b){var c=g4;g4=new $CLJS.k(null,2,[l4,j4(a),$CLJS.OC,j4(b)],null);try{var d=$CLJS.tj($CLJS.n4,$CLJS.yG);return d.h?d.h(a,b):d.call(null,a,b)}finally{g4=c}});
$CLJS.n4.o(null,$CLJS.LP,function(a,b){var c=$CLJS.J(a,0,null),d=$CLJS.J(a,1,null),e=$CLJS.J(a,2,null),f=$CLJS.J(b,0,null),g=$CLJS.J(b,1,null),l=$CLJS.J(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.h(c,f))?(d=$CLJS.n4.h(d,g),$CLJS.m(d)?$CLJS.m(g4)?$CLJS.F.h(h4(new $CLJS.S(null,2,5,$CLJS.T,[l4,e],null),Nqa),h4(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OC,l],null),Mqa)):$CLJS.F.h(e,l):d):c:a});
$CLJS.n4.o(null,$CLJS.Pj,function(a,b){if($CLJS.oe(a)){var c=$CLJS.tj($CLJS.n4,$CLJS.yG);return c.h?c.h(a,b):c.call(null,a,b)}return $CLJS.ne(a)?(c=$CLJS.tj($CLJS.n4,$CLJS.wG),c.h?c.h(a,b):c.call(null,a,b)):$CLJS.F.h(a,b)});var o4=function o4(a){switch(arguments.length){case 2:return o4.h(arguments[0],arguments[1]);case 3:return o4.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
o4.h=function(a,b){for(var c=$CLJS.Ye,d=new $CLJS.S(null,4,5,$CLJS.T,[function(){return function(g){return $CLJS.cW(g,$CLJS.u0,$CLJS.G([$CLJS.NR,$CLJS.Gm,$CLJS.y3,$CLJS.tl]))}}(c),Lqa,function(){return function(g){return $CLJS.cW(g,$CLJS.Gm,$CLJS.G([$CLJS.CG,$CLJS.lk]))}}(c),function(){return function(g){return $CLJS.cW(g,$CLJS.Gm,$CLJS.G([$CLJS.kQ]))}}(c)],null);;){var e=function(){var g=c.g?c.g(a):c.call(null,a);return $CLJS.tW(function(l,n,q){return function(u){return $CLJS.n4.h(l.g?l.g(u):l.call(null,
u),q)}}(c,d,g),b)}();if($CLJS.m(e))return e;if($CLJS.A(d)){e=$CLJS.Om.h(c,$CLJS.C(d));var f=$CLJS.zd(d);c=e;d=f}else return null}};
o4.j=function(a,b,c){var d=o4.h(b,c);return $CLJS.m(d)?d:$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function l(f,g){try{if($CLJS.qe(g)&&3===$CLJS.E(g))try{var n=$CLJS.Td(g,0);if($CLJS.O(n,$CLJS.CQ))try{var q=$CLJS.Td(g,2);if($CLJS.Ae(q)){var u=$CLJS.Td(g,2),v=$CLJS.Td(g,1);n=$CLJS.T;var x=$CLJS.V.g($CLJS.b4(a,u));var y=$CLJS.m(x)?o4.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CQ,v,x],null),c):null;return new $CLJS.S(null,1,5,n,[y],null)}throw $CLJS.Z;}catch(H){if(H instanceof Error){var B=H;if(B===$CLJS.Z)throw $CLJS.Z;
throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)return $CLJS.qZ(l,f,g);throw B;}throw H;}}($CLJS.Lg,b))))};o4.v=3;$CLJS.p4=$CLJS.hG(o4,8);