import styled from "@emotion/styled";

export const ErrorPageRoot = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
