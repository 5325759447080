var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var oL,xga,sL,xL;
$CLJS.mL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return $CLJS.Iu([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join(""))};$CLJS.nL=function(a){if("string"===typeof a){var b=$CLJS.Di(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.go(a));};oL=function(a){return $CLJS.NH(a,$CLJS.dl)||$CLJS.NH(a,$CLJS.Qj)};
$CLJS.pL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-zero","metabase.lib.schema.common/int-greater-than-zero",1130338092);xga=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.qL=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.rL=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
sL=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.tL=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.uL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.vL=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.wL=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);xL=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.iL($CLJS.rL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rl,new $CLJS.k(null,1,[$CLJS.rk,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,1,[$CLJS.ml,"non-blank string"],null),$CLJS.$f($CLJS.OF)],null)],null));$CLJS.iL($CLJS.uL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.St,new $CLJS.k(null,1,[$CLJS.rk,0],null)],null));$CLJS.iL($CLJS.pL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.St,new $CLJS.k(null,1,[$CLJS.rk,1],null)],null));
$CLJS.iL(xL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rl,new $CLJS.k(null,2,[$CLJS.rk,36,$CLJS.am,36],null)],null));$CLJS.iL(sL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ml,"valid semantic type",$CLJS.Yu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.sl);return["Not a valid semantic type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),oL],null));
$CLJS.iL($CLJS.wL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.It,new $CLJS.k(null,2,[$CLJS.ml,"valid base type",$CLJS.Yu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.sl);return["Not a valid base type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.NH(a,$CLJS.pl)&&!oL(a)}],null));
$CLJS.iL($CLJS.vL,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HG,xL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.wL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.wL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nl,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,
sL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tL,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.rL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.rL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SI,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Kt,$CLJS.rL],null)],null)],null));
$CLJS.iL(xga,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jm,$CLJS.qL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OJ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ck,$CLJS.Rl,$CLJS.Fk],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ok,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.vL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ol,$CLJS.Wt],null)],null)],null));