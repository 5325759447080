var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var yM,zM,sM;
$CLJS.Iga=function(a,b,c){var d=$CLJS.rM.g(a),e=$CLJS.mM(a,b),f=$CLJS.m(d)?function(l){try{var n=d.g?d.g(l):d.call(null,l)}catch(q){if(q instanceof Object)n=sM;else throw q;}return $CLJS.F.h(sM,n)?$CLJS.eM($CLJS.$L($CLJS.fm.g(a),l,new $CLJS.Yi(function(){return new $CLJS.gf(null,$CLJS.Ui.g($CLJS.WL(d)),new $CLJS.gf(null,$CLJS.VL(l),null,1,null),2,null)}),$CLJS.hM)):$CLJS.m(n)?e.g?e.g(l):e.call(null,l):c.g?c.g(l):c.call(null,l)}:e,g=$CLJS.tM.g(a);return $CLJS.m(g)?function(l){l=f.g?f.g(l):f.call(null,
l);var n=$CLJS.gM(l);return $CLJS.m(n)?$CLJS.eM(g.g?g.g(n):g.call(null,n)):l}:f};$CLJS.uM=function(a,b,c,d,e,f,g){this.na=a;this.options=b;this.bc=c;this.Ub=d;this.F=e;this.m=f;this.G=g;this.C=2230716170;this.K=139264};$CLJS.wM=function(a,b){return $CLJS.vM(a,b,null)};$CLJS.vM=function(a,b,c){return $CLJS.xM(a,b,c,null)};
$CLJS.xM=function(a,b,c,d){if(!$CLJS.m($CLJS.m(c)?c:null==$CLJS.rM.g($CLJS.$d(b))))throw Error($CLJS.UL("when last option has a guard, err-f must be provided"));return new $CLJS.uM(a,b,c,d,null,null,null)};yM=new $CLJS.N(null,"err-f","err-f",651620941);zM=new $CLJS.N(null,"post","post",269697687);sM=new $CLJS.N("schema.spec.variant","exception","schema.spec.variant/exception",-253680523);$CLJS.tM=new $CLJS.N(null,"wrap-error","wrap-error",536732809);$CLJS.rM=new $CLJS.N(null,"guard","guard",-873147811);$CLJS.h=$CLJS.uM.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "pre":return this.na;case "options":return this.options;case "err-f":return this.bc;case "post":return this.Ub;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Td=$CLJS.La(55);
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.spec.variant.VariantSpec{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.oM,this.na],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ok,this.options],null),new $CLJS.S(null,2,5,$CLJS.T,[yM,this.bc],null),new $CLJS.S(null,2,5,$CLJS.T,[zM,this.Ub],null)],null),this.m))};
$CLJS.h.oa=function(){return new $CLJS.eh(this,4,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.oM,$CLJS.Ok,yM,zM],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 4+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-1524878868^$CLJS.Ed(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.na,b.na)&&$CLJS.F.h(this.options,b.options)&&$CLJS.F.h(this.bc,b.bc)&&$CLJS.F.h(this.Ub,b.Ub)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.oM,null,yM,null,zM,null,$CLJS.Ok,null],null),null),b)?$CLJS.Gm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.uM(this.na,this.options,this.bc,this.Ub,this.F,$CLJS.Rf($CLJS.Gm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "pre":case "options":case "err-f":case "post":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.oM,b):$CLJS.O.call(null,$CLJS.oM,b))?new $CLJS.uM(c,this.options,this.bc,this.Ub,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.Ok,b):$CLJS.O.call(null,$CLJS.Ok,b))?new $CLJS.uM(this.na,c,this.bc,this.Ub,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(yM,b):$CLJS.O.call(null,yM,b))?new $CLJS.uM(this.na,this.options,c,this.Ub,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h(zM,b):$CLJS.O.call(null,zM,b))?new $CLJS.uM(this.na,
this.options,this.bc,c,this.F,this.m,null):new $CLJS.uM(this.na,this.options,this.bc,this.Ub,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.Je($CLJS.oM,this.na),new $CLJS.Je($CLJS.Ok,this.options),new $CLJS.Je(yM,this.bc),new $CLJS.Je(zM,this.Ub)],null),this.m))};$CLJS.h.O=function(a,b){return new $CLJS.uM(this.na,this.options,this.bc,this.Ub,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};